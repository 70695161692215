import React, { useEffect, useState,useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { MdOutlineLocationOn, MdOutlineSupportAgent } from "react-icons/md";
import { FaRegHospital } from "react-icons/fa";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { Button, Dropdown } from 'react-bootstrap';
import {MdOutlineRefresh} from "react-icons/md"
import {CiMenuKebab} from "react-icons/ci"
const ClientEdit = () => {
    let { id } = useParams();
    const [popupType, setpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const MastersModal = Masters.Client
    const [EditData, SetEditData] = useState()
    const[check,setCheck]=useState(false)
    const [Clientsinfo, SetClientinfo] = useState({
        client_role: "",
        name: "",
        area: "",
        address: "",
        phone: "",
        email: ""
    })
    const [ClientsEdit, SetClientEdit] = useState({
        client_role: "",
        name: "",
        area: "",
        address: "",
        phone: "",
        email: "",
    })
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        SetClientinfo({ ...Clientsinfo, [name]: value })
    }
    const HandleClientEdit = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        SetClientEdit({ ...ClientsEdit, [name]: value })
    }
    console.log(ClientsEdit)
    const [Error, SetError] = useState("")
    const ClientinfonSubmit = (e) => {
        e.preventDefault()
        const regx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!Clientsinfo.email.match(regx)) {
            SetError("Email is Not Valid")
        }
        else {
            axiosConfig.post("/client/client-info/", {
                client_role: Clientsinfo.client_role,
                name: Clientsinfo.name,
                area: Clientsinfo.area,
                address: Clientsinfo.address,
                phone: Clientsinfo.phone,
                email: Clientsinfo.email, client: id
            }).then(res => {
                SetClientinfo({
                    name: "",
                    area: "",
                    address: "",
                    phone: "",
                    email: ""
                })
                SetError("")
                setpopupType("success")
                GetData()
                setShow(!Show)
            }).catch(error => {
                setpopupType("error")
            })
        }
    }
    const formSubmit = (Data) => {
        axiosConfig.patch(`/client/clients/${id}/`, Data).then(res => {
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data.error)
            const ErrorData = []
            ErrorData.push({
                client_code: error.response.data.error || ["null"],
            })
            if (ErrorData[0].client_code === "Oops, the client Fields already exists. Please choose to enter a different Field Value.") {
                SetError("Code Already Exists")
            }
            setpopupType("error")
        })
    }
    const [Data, SetData] = useState([])
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/client/client-info/?client=${id}`).then(res => {
            SetData(res.data.results)
            setLoading(false)

        }).catch(error => {
            console.log(error)
        })
    }
    const clientinfoEdit = (clientinfoid) => {
        const { client_role,
            name,
            area,
            address,
            phone,
            email, } = ClientsEdit
        axiosConfig.put(`/client/client-info/${clientinfoid}/`, {
            client_role: ClientsEdit.client_role,
            name: ClientsEdit.name,
            area: ClientsEdit.area,
            address: ClientsEdit.address,
            phone: ClientsEdit.phone,
            email: ClientsEdit.email,
            client: id
        }).then(res => {
            console.log(res.data)
            // SetClientEdit(res.data)
            SetEditData(null)
            GetData();
        }).catch(error => {
            console.log(error)
        })
    }
    const [ClientData, SetClientData] = useState([])
    const ReferenceDoamain = () => {
        axiosConfig.get("/masters/referencedomainvalue/?domain=1&domain__code=CLIENTROLE").then(res => {
            SetClientData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const [area, SetArea] = useState([])
    const Area = () => {
        axiosConfig.get("/masters/area/").then(res => {
            console.log(res)
            SetArea(res.data.results) 
        }).catch(error => {
            console.log(error)
        })
    }
    const [Clients, SetClients] = useState([])
    const clients = () => {
        axiosConfig.get("/client/clients/").then(res => {
            SetClients(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [clientsEditData, SetClientEditData] = useState([])
    const clientEditData = () => {
        axiosConfig.get(`/client/clients/${id}/`).then(res => {
            SetClientEditData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        clientEditData()
        clients()
        Area()
        ReferenceDoamain()
        GetData()
    }, [])
    const HandleEdit = (id) => {
        SetEditData(id)
        axiosConfig.get(`/client/client-info/${id}/`).then(res => {
            SetClientEdit(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [RowId, SetRowId] = useState()
    const DeleteAlert = (Tariffid) => {
        SetRowId(Tariffid)
        SetAlertDeletePopupType("success")
    }
    const HandleDelete = (id) => {
        console.log(id)
        axiosConfig.delete(`/client/client-info/${id}/`).then(res => {
            GetData();
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")

        }).catch(error => {
            setDeletepopupType("error")
            console.log(error)
        })
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption.value, name)
        // const name = e.target.name
        //  const value=e.target.value
        // //  console.log(name,value)
        SetClientinfo({ ...Clientsinfo, [name]: selectedOption.value })
    };
    console.log(Clientsinfo)
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client</h5>
                    <NavLink to="/dashboard/users/clients" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="accordion-container">
                    <div className="accordion" id="accordionClient">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="dashboard-subtitle">View / Edit Client</h5>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <FormParser modelObject={MastersModal} formSubmit={formSubmit} formData={clientsEditData} error={Error} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <h5 className="dashboard-subtitle">Client Additional Information</h5>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <div className="clientbill-info">
                                        <div className="clientinformation">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <p><FaRegHospital /> #{clientsEditData.id && clientsEditData.id} {clientsEditData.client_name && clientsEditData.client_name}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p><MdOutlineLocationOn /> {clientsEditData.email && clientsEditData.email} | {clientsEditData.phone && clientsEditData.phone}</p>
                                                </div>
                                                <div className="col-md-3">
                                                    <p><MdOutlineSupportAgent /> {clientsEditData.address && clientsEditData.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" onClick={() => setShow(!Show)}>Add New</button>
                                    </div>
                                    <Modal
                                        size="lg"
                                        show={Show}
                                        onHide={() => setShow(!Show)}
                                        aria-labelledby="example-modal-sizes-title-sm">
                                        <Modal.Header closeButton>
                                            <Modal.Title id="example-modal-sizes-title-sm">

                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {Error && <div className="Loginerror">
                                                <p>{Error}</p>
                                            </div>}
                                            <form onSubmit={ClientinfonSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Role:</label>
                                                        <Select options={ClientData && ClientData.map((optionItem) => (
                                                            { "value": optionItem.id, "label": optionItem.code }
                                                        ))} placeholder="SELECT Client Role" onChange={(selectedOption) => handleSelectChange(selectedOption, "client_role")} required />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Name:</label>
                                                            <input type="text" className="form-control" placeholder="Name" name="name" value={Clientsinfo.name} onChange={HandleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Area:</label>
                                                        <Select options={area && area.map((optionItem) => (
                                                            { "value": optionItem.id, "label": optionItem.address }
                                                        ))} placeholder="SELECT Area" onChange={(selectedOption) => handleSelectChange(selectedOption, "area")} required />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Address:</label>
                                                            <textarea className="form-control" rows="2" placeholder="Address" name="address" onChange={HandleChange} required value={Clientsinfo.address}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Phone Number:</label>
                                                            <input type="number" className="form-control" placeholder="Phone Number" name="phone" value={Clientsinfo.phone} onChange={HandleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Email:</label>
                                                            <input type="text" className="form-control" placeholder="Email" name="email" value={Clientsinfo.email} onChange={HandleChange} required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </Modal>
                                    <div className="tablescroll-clientbill">
                                         <div className="table-header-menu">
                                             <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                                 <Dropdown.Toggle variant="">
                                                     <input
                                                         type="checkbox"
                                                         className="form-check-input"
                                                         onClick={handleCheckboxClick}
                                                     />
                                                 </Dropdown.Toggle>
                                                 <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                                     <Dropdown.Item>Action</Dropdown.Item>
                                                     <Dropdown.Item>Another action</Dropdown.Item>
                                                     <Dropdown.Item>Something else here</Dropdown.Item>
                                                     <Dropdown.Divider />
                                                     <Dropdown.Item>Separated link</Dropdown.Item>
                                                 </Dropdown.Menu>
                                             </Dropdown>
                                             <MdOutlineRefresh className="sidebar-icon-table" />
                                             <CiMenuKebab className="sidebar-icon-table" />
                                              {/* <nav class="pagination-totalpages">
                                                 <span class="hOXYFc">Rows per page:</span>
                                                 <select>
                                                     <option value="10">10</option>
                                                     <option value="20" selected="">20</option>
                                                     <option value="25">25</option>
                                                     <option value="30">30</option>
                                                     <option value="40">40</option>
                                                     <option value="50">50</option>
                                                 </select>
                                                 <span class="fwhfat">1-20 of 22</span>
                                                 <div class="bcYJHc"><button id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="" className="gryXwN"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></button>
                                                     <button id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled="" className="gryXwN"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                                                     <button id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="false" className="gryXwN" fdprocessedid="8wrtk"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                                                     <button id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="false" className="gryXwN" fdprocessedid="mhvkeh"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></button>
                                                 </div></nav> */}
                                                 </div>  
                                    <div className="table-responsive">
                                        {loading ? (<div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        </div>) : Data.length === 0 ? <p className="text-center"> There are no records to display</p> :
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Client Role</th>
                                                        <th>Name</th>
                                                        <th>Area</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>Email</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Data && Data.map((clientinfo, id) => {
                                                        return (
                                                            EditData === clientinfo.id ?
                                                                <tr key={clientinfo.id}>
                                                                    <td>{clientinfo.id}</td>
                                                                    <td>
                                                                        <select className="form-select" name="client_role" value={ClientsEdit.client_role} onChange={HandleClientEdit} required>
                                                                            <option value="">SELECT Client Role</option>
                                                                            {
                                                                                ClientData.map((optionItem, i) => {
                                                                                    return (
                                                                                        <option value={optionItem.id} key={i}>{optionItem.code}</option>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" placeholder="Name" name="name" value={ClientsEdit.name} onChange={HandleClientEdit} />
                                                                        </div>
                                                                    </td>
                                                                    <td>

                                                                        <select className="form-select" name="area" value={ClientsEdit.area} onChange={HandleClientEdit} required>
                                                                            <option value="">SELECT Area</option>
                                                                            {
                                                                                area.map((optionItem, i) => {
                                                                                    return (
                                                                                        <option value={optionItem.id} key={i}>{optionItem.address}</option>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <textarea className="form-control" rows="2" placeholder="Address" name="address" onChange={HandleClientEdit} required value={ClientsEdit.address}></textarea>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <input type="number" className="form-control" placeholder="Phone Number" name="phone" value={ClientsEdit.phone} onChange={HandleClientEdit} required />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" placeholder="Email" name="email" value={ClientsEdit.email} onChange={HandleClientEdit} required />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-primary" onClick={(id) => clientinfoEdit(clientinfo.id)}>Update</button>
                                                                    </td>
                                                                </tr> :
                                                                <tr key={clientinfo.id}>
                                                                    <td>{clientinfo.id}</td>
                                                                    <td>{clientinfo.client_role_name}</td>
                                                                    <td>{clientinfo.name}</td>
                                                                    <td>{clientinfo.area_name}</td>
                                                                    <td>{clientinfo.address}</td>
                                                                    <td>{clientinfo.phone}</td>
                                                                    <td>{clientinfo.email}</td>
                                                                    <td>
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={(id) => HandleEdit(clientinfo.id)} />
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={(Tariffid) => DeleteAlert(clientinfo.id)} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        AlertDeletePopupType === "success" &&
                                                                        <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                                                                    }
                                                                </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message="Record Updated Sucessfully" />
                }
            </DashboardLayout>
        </>
    )

}
export { ClientEdit }