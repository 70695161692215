import React, { useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const SubModuleAdd = () => {
    const MastersModal=Masters.SubModule
    const[popupType,setpopupType]=useState("")
    const[Error,SetError]=useState("")
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/submodule/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error.response.data.name)
          let  ErrorData=[]
            ErrorData.push({
                name:error.response.data.name||["null"],
                code:error.response.data.code||["null"],
                })
          if(ErrorData[0].name[0]==="This field must be unique."){
            SetError("Name Already Exists.")

           }
           if(ErrorData[0].code[0]==="This field must be unique."){
            SetError("Name Already Exists.")

           }
            setpopupType("error")
        })
      }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Sub Module</h5>
                    <NavLink to="/dashboard/masters/submodule" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
            </DashboardLayout>
            {
                popupType!==""&&
                <PopupModal Navigation="/dashboard/masters/submodule" popupType={popupType} />
            }
        </>
    )

}
export {SubModuleAdd}