import React,{useState,useEffect} from "react";
import Masters from "../Components/masters.json"
import { DashboardLayout, DeletePopupModal,PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig"
const ProfessionalDetails=({data,HandleCount,stateID})=>{
    const masterModal=Masters.ProfessionalDetails
    const [Error, SetError] = useState("")
    const[popupType,setpopupType]=useState("")
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [propData,setPropData]=useState({
        qualification:data.qualification,
        registration_number:data.registration_number,
        experience:data.experience
    })
    const formSubmit = (Data) => {
       if(Data==="Back"){
        HandleCount(1)
       }
       else{
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.patch(`/account/users/${data.id}/`, Data).then(res => {
            console.log(res)
                data["qualification"]=res.data.qualification
                data["registration_number"]=res.data.registration_number
                data["experience"]=res.data.experience
            HandleCount(3)
            setpopupType("success")
            data=res.data
        }).catch(error => {
            setpopupType("error")
        })
       }
    }

    return(
        <div>
        <FormParser modelObject={masterModal} buttonTitle="Save" formSubmit={formSubmit}  formData={propData}/>
        {popupType != "" &&
         <PopupModal popupType={popupType} setpopupType={setpopupType}/>
     }
        </div>
    )
}
export {ProfessionalDetails}