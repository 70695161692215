import React, { useState, useEffect } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const ReferenceDoaminValueAdd = () => {
    const[Error,SetError]=useState("")
    const [popupType, setpopupType] = useState("");
    let { id } = useParams();

    const MastersModal=Masters.ReferenceDomainValue
    const formSubmit=(Data)=>{
        console.log({...Data,id:id})
        axiosConfig.post("/masters/referencedomainvalue/",{...Data,domain:id}).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                code:error.response.data.non_field_errors||["null"]
                })
                console.log(ErrorData[0].code)
                if(ErrorData[0].code[0]==="The fields domain, code must make a unique set."){
                    SetError("Code Already Exists")
                }
            setpopupType("error")

        })
      }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add DomainValue{id}</h5>
                    <NavLink to="/dashboard/masters/domains/value" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/domains/value" />
                }
            </DashboardLayout>
        </>
    )

}
export {ReferenceDoaminValueAdd}