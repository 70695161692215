import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const RoleAdd = () => {
    const MastersModal=Masters.Role
    const[popupType,setpopupType]=useState("")
    const[id,setId]=useState()
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/account/roleview/",Data).then(res=>{
                   console.log(res)
                   setId(res.data.id)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            setpopupType("error")
        })
      }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Role</h5>
                    <NavLink to="/dashboard/masters/role" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={`/dashboard/masters/role/${id}/edit`} Message="Role Added Successfully "/>
                }
            </DashboardLayout>
        </>
    )

}
export {RoleAdd}