import React, { useEffect, useState,useRef } from "react";
import { DashboardLayout,DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import {MdOutlineRefresh} from "react-icons/md"
import {CiMenuKebab} from "react-icons/ci"
const PriorityList = ({Code}) => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const[deletePopupType,setDeletepopupType]=useState("")
    const [RowId, SetRowId] = useState()
    const[loading,setLoading]=useState(false)
    let navigate = useNavigate();
    const[actionPermisson,SetActionPermission]=useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const[check,setCheck]=useState(false)
    const[tableId,setTableId]=useState()
    const[search,SetSearch]=useState({
        search:""
    })
    const[Datafilter,SetDatafilter]=useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/masters/priority/?page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    }; 
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`; 
        setDataTableHeight(newHeight);
    };       
    useEffect(() => {
        calculateDataTableHeight()
        GetData();
        PermitData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/masters/priority/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const PermitData=()=>{
        const Permmitdata=localStorage.getItem("permission")
        const data=JSON.parse(Permmitdata)
        let moduledata=[]
       const find_data=data.find(res=>res.modulescreen.code===Code)
         SetActionPermission(find_data)
    }
    
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleMouse=(row)=>{
        setTableId(row.id)
    }
    const HandleRowmouseLeave=()=>{
        setTableId()
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Code",
            selector: "code",
            cell: (row) => `${row.code}`,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: (row) => `${row.name}`,
            sortable: true
        },
        {
            name: "Tat In Minutes",
            selector: "tat_in_minutes",
            cell: (row) => `${row.tat_in_minutes}`,
            sortable: true
        },
        {
            name: "From Time",
            selector: "from_time",
            cell: (row) => `${row.from_time}`,
            sortable: true
        },
        {
            name: "To Time",
            selector: "to_time",
            cell: (row) => `${row.to_time}`,
            sortable: true
        }
        ,{
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async() => {
                    return navigate(`/dashboard/masters/priority/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt=()=>{
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async(id) => {
                    await axiosConfig.delete(`/masters/priority/${id}/`).then(()=>{
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData()
                    }).catch((error)=> {
                        setDeletepopupType("error")
                        console.log(error)
                    })
                }
                const HandleView=()=>{
                    navigate(`/dashboard/masters/priority/${row.id}/edit`)
                }
                const shouldShowActions = tableId === row.id;
                return (
                    <>
                    {
                        shouldShowActions && (
                            <div className="row mx-auto" >
                            {actionPermisson&&actionPermisson.read&&<div className="col-4">
                             <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleView}/>
                             </div>}
                             {actionPermisson&&actionPermisson.edit&&<div className="col-4">
                                 <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                             </div>}
                             {actionPermisson&&actionPermisson.delete&&<div className="col-4">
                                 <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                             </div>}
                         </div>
                        )
                    }
                        {
                            AlertDeletePopupType=== "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Priority</h5>
                    {actionPermisson&&actionPermisson.write&&<NavLink to="/dashboard/masters/priority/add" ><button className="btn btn-white">Add New</button></NavLink>}
                </div>
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                       </div>
                <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page, totalRows) => {
                            setCurrentPage(page);
                            GetData(currentColumn, currentDirection);
                        }}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        fixedHeader
                        customStyles={tableCustomStyles}
                        fixedHeaderScrollHeight={dataTableHeight}
                        selectableRows
                        onRowMouseEnter={HandleMouse}
                        onRowMouseLeave={HandleRowmouseLeave}
                          onSelectedRowsChange={({ selectedRows }) =>
                          console.log(selectedRows)
                        }
                        selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        progressPending={loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                        subHeaderComponent={
                            <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}>
                    </DataTable>
                </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { PriorityList }