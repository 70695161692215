import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
const ClientAdd = () => {
    let Navigate = useNavigate();
    const [popupType, setpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [formerrorData, SetformErrorData] = useState({})
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const MastersModal = Masters.Client
    const [Error, SetError] = useState("")
    const [EditData, SetEditData] = useState()
    const [Clientsinfo, SetClientinfo] = useState({
        client_role: "",
        name: "",
        area: "",
        address: "",
        phone: "",
        email: "",
        client_id: ""
    })
    const [ClientsEdit, SetClientEdit] = useState({
        client_role: "",
        name: "",
        area: "",
        address: "",
        phone: "",
        email: "",
        client_id: ""
    })
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetClientinfo({ ...Clientsinfo, [name]: value })
    }
    const HandleClientEdit = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetClientEdit({ ...ClientsEdit, [name]: value })
    }
    const ClientinfonSubmit = (e) => {
        e.preventDefault()
        console.log(Clientsinfo)
        SetClientinfo({
            name: "",
            area: "",
            address: "",
            phone: "",
            email: "",
        })
        axiosConfig.post("/client/client-info/", Clientsinfo).then(res => {
            console.log(res)
            setpopupType("success")
            GetData()
            setShow(!Show)
        }).catch(error => {
            setpopupType("error")
        })
    }
    const [clientid, SetClientid] = useState()
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("/client/clients/", Data).then(res => {
            console.log(res)
            SetClientid(res.data.id)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data)
            const ErrorData = []
            ErrorData.push({
                client_code: error.response.data.error || ["null"],
            })
            if (ErrorData[0].client_code === "The client Code Field already exists. Please choose to enter a different Field Value.") {
                SetError("Code Already Exists")
            }
            setpopupType("error")
        })
    }
    console.log(Error)
    const [Data, SetData] = useState([])
    const GetData = () => {
        axiosConfig.get("/client/clients/").then(res => {
            console.log(res.data)
            SetData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const clientinfoEdit = (id) => {
        console.log(id)
        const { client_role,
            name,
            area,
            address,
            phone,
            email,
            client_id } = ClientsEdit
        axiosConfig.put(`/client/client-info/${id}/`, ClientsEdit).then(res => {
            console.log(res.data)
            SetClientEdit(res.data)
            SetEditData(null)
            GetData();
        }).catch(error => {
            console.log(error)
        })
    }
    const [ClientData, SetClientData] = useState([])
    const ReferenceDoamain = () => {
        axiosConfig.get("/masters/referencedomainvalue/?domain=59").then(res => {
            console.log(res.data)
            SetClientData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [area, SetArea] = useState([])
    const Area = () => {
        axiosConfig.get("/masters/area/").then(res => {
            console.log(res.data)
            SetArea(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Clients, SetClients] = useState([])
    const clients = () => {
        axiosConfig.get("/client/clients/").then(res => {
            console.log(res.data)
            SetClients(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        clients()
        Area()
        ReferenceDoamain()
        GetData()
    }, [])
    const HandleEdit = (id) => {
        console.log(id)
        SetEditData(id)
        axiosConfig.get(`/client/client-info/${id}`).then(res => {
            console.log(res.data)
            SetClientEdit(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Client</h5>
                    <NavLink to="/dashboard/users/clients" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="">
                    <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} />
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={`/dashboard/users/clients/${clientid}/edit`} />
                }
            </DashboardLayout>
        </>
    )

}
export { ClientAdd }
