import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, EditPopupModal, DeletePopupModal, Pagination, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineEdit } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { PriorityAdd } from ".";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles"
import { FaRegHospital } from "react-icons/fa";
import Select from 'react-select';
const TariffCardEdit = () => {
    let { id } = useParams();
    const [popupType, setpopupType] = useState("");
    const [EditPopupType, setEditpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const MastersModal = Masters.TariffCard
    const [Error, SetError] = useState("")
    const [EditData, SetEditData] = useState()
    const [Clientsinfo, SetClientinfo] = useState({
        serice_amount: "",
        night_amount: "",
        alias_code: "",
        alias_name: "",
        tariffcategory: "",
        service: "",
        priority: "",
        client: ""
    })
    const [Name, setName] = useState()
    const FilterData = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        setName(value)
        const filteredData = DataFilter.filter(item => item.subdepartment_code.includes(value));
        console.log(filteredData)
        SetServiceAmount(filteredData)
        setPerpage(filteredData.slice(0, pageNumber ? pageNumber : 30))
    }
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetClientinfo({ ...Clientsinfo, [name]: value })

    }
    const [EditTariff, SetEditTarif] = useState([])
    const [EditTariffid, SetEditTarifid] = useState({})
    let data = []
    const HandleEditChange = (e, service) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(value)
        const existingIndex = EditTariff.findIndex((item) => item.id === service);
        if (existingIndex === -1) {
            SetEditTarif([...EditTariff, { "id": service, "amount": value }]);
        }
        else {
            const updatedValues = [...EditTariff];
            updatedValues[existingIndex] = { "id": service, "amount": value };
            SetEditTarif(updatedValues);
        }
    }
    console.log(EditTariff)
    const [ClientsEdit, SetClientEdit] = useState([])
    const [DefaultData, SetDefaultData] = useState([])
    const HandleEdit = (id) => {
        let TarifPriceData = []
        let defobj = {}
        let editTariff = []
        // console.log(id)
        SetEditData(id)
        const FindData = perpage.find(data => data.service === id)
        console.log(FindData)
        FindData.prices.forEach(res => {
            console.log(res)
            TarifPriceData.push({ "service": res.id, [res.id + "_" + id]: res.serice_amount })
            defobj[res.id + "_" + id] = res.serice_amount
            editTariff.push({ "id": res.id, "amount": res.serice_amount });
        })
        SetClientEdit(TarifPriceData)
        SetDefaultData(defobj)
        SetEditTarif(editTariff)
    }
    const ClientinfonSubmit = (e) => {
        e.preventDefault()
        // console.log(Clientsinfo)
        SetClientinfo({
            serice_amount: "",
            night_amount: "",
            alias_code: "",
            alias_name: "",
        })
        const { serice_amount,
            night_amount,
            alias_code,
            alias_name,
            tariffcategory,
            service,
            priority,
            client } = Clientsinfo
        axiosConfig.post("/client/clienttariff/", Clientsinfo).then(res => {
            console.log(res)
            setpopupType("success")
            GetData()
            setShow(!Show)
        }).catch(error => {
            setpopupType("error")
        })
    }
    const [TariffId, SetTariffId] = useState()
    const [Data, SetData] = useState([])
    const [DataFilter, SetDataFilter] = useState([])
    const GetData = async () => {
        let tariff_list = []
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/client/clienttariff/?tariffcategory=${id}`)
            console.log(response.data)
            response.data.forEach((obj, index) => {
                if (tariff_list.findIndex(list_obj => list_obj.service === obj.service) === -1) {
                    tariff_list.push({
                        "service": obj.service, "service_name": obj.service_name, "subdepartment_code": obj.subdepartment_code, "prices": [{
                            "id": obj.id,
                            "serice_amount": obj.serice_amount,
                            "priority": obj.priority,
                            "priority_name": obj.priority_name
                        }]
                    })
                } else {
                    let exist_idx = tariff_list.findIndex(list_obj => list_obj.service === obj.service)
                    tariff_list[exist_idx]['prices'].push({
                        "id": obj.id,
                        "serice_amount": obj.serice_amount,
                        "priority": obj.priority,
                        "priority_name": obj.priority_name
                    })
                }
                setLoading(false)
            })
            // var result = response.data.reduce((curr, next) => {
            //     (curr[next.service] = curr[next.service] || []).push(next);
            //     return curr;
            // }, {});
            // GetPriorityPrice(result)
            //     console.log(result)
        }
        catch (error) {
            console.log(error)
        }
        // console.log(tariff_list)
        SetServiceAmount(tariff_list)
        SetDataFilter(tariff_list)
        setPerpage(tariff_list.slice(0, pageNumber ? pageNumber : 30))
    }
    const [perpage, setPerpage] = useState([])
    const [pageNumber, SetPageNumber] = useState()
    const HandlePerPage = (pageNumber) => {
        // console.log(pageNumber)
        SetPageNumber(pageNumber)
        setPerpage(ServiceAmount.slice(0, pageNumber))
    }
    const HandleNextPage = (pageNumber, page) => {
        console.log(pageNumber, page)
        setPerpage(ServiceAmount.slice(page * pageNumber - pageNumber, page * pageNumber))
    }
    const HandleStartPage = () => {
        setPerpage(ServiceAmount.slice(0, 30))
    }
    const HandleEndPage = (end, endPage) => {
        console.log(end, endPage)
        setPerpage(ServiceAmount.slice(endPage * end - end, end * endPage))
    }
    const [ServiceAmount, SetServiceAmount] = useState([])
    const [Editdata, SetEditdata] = useState([])
    const clientinfoEdit = (e) => {
        e.preventDefault()
        console.log(id)
        const updatedValues = [...perpage]
        const Index = updatedValues.findIndex(data => data.service === EditData)
        console.log(EditTariff[0].amount)
        updatedValues[Index]["prices"][0].serice_amount = EditTariff[0].amount
        updatedValues[Index]["prices"][1].serice_amount = EditTariff[1].amount
        updatedValues[Index]["prices"][2].serice_amount = EditTariff[2].amount
        setPerpage(updatedValues)
        EditTariff.forEach(res => {
            axiosConfig.put(`/client/clienttariff/${res.id}/`, { serice_amount: res.amount }).then(res => {
                console.log(res.data)
                setEditpopupType("success")
                SetEditData(null)
                SetDefaultData(res.data.serice_amount)

            }).catch(error => {
                setEditpopupType("error")
                console.log(error)
            })
        })
    }
    const [ClientData, SetClientData] = useState([])
    const ReferenceDoamain = () => {
        axiosConfig.get("/masters/tariffcategories/").then(res => {
            console.log(res.data)
            SetClientData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [ServiceData, SetServiceData] = useState([])
    const Service = () => {
        axiosConfig.get("masters/services/").then(res => {
            // console.log(res.data)
            SetServiceData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Priority, SetPriority] = useState([])
    const PriorityData = () => {
        axiosConfig.get("/masters/priority/").then(res => {
            // console.log(res.data)
            SetPriority(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Clients, SetClients] = useState([])
    const clients = () => {
        axiosConfig.get("/client/clients/").then(res => {
            // console.log(res.data)
            SetClients(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [TariffEditData, SetTariffEditData] = useState([])
    const [TarifChange, setTariffChange] = useState({
        tarifftype: ""
    })
    const clientEditData = () => {
        axiosConfig.get(`/masters/tariffcategories/${id}/`).then(res => {
            console.log(res.data)
            SetTariffEditData(res.data)
            setTariffChange({ tarifftype: res.data.tarifftype })
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        PriorityData()
        clientEditData()
        clients()
        Service()
        ReferenceDoamain()
        GetData()
    }, [])
    const [RowId, SetRowId] = useState()
    const DeleteAlert = (service) => {
        const FindData = ServiceAmount.find(data => data.service === service)
        let data = []
        FindData.prices.forEach(res => {
            data.push(res.id)
        })

        SetRowId(data)
        SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = (id) => {
        console.log(id)
        id.forEach(res => {
            axiosConfig.delete(`/client/clienttariff/${res}/`).then(res => {
                console.log(res)
                setDeletepopupType("success")
                SetAlertDeletePopupType("error")
                GetData();
            }).catch(error => {
                console.log(error)
                SetAlertDeletePopupType("error")
                setDeletepopupType("error")
            })
        })

    }
    const [DefaultPrices, SetDefaultPrices] = useState([])
    const GetPriorities = async () => {
        try {
            const response = await axiosConfig.get(`/masters/priority/`)
            let defobj = []
            response.data.forEach((obj, index) => {
                defobj.push(obj.code)
            })
            SetDefaultPrices(defobj)
        }
        catch (error) {
            console.log(error)
        }

    }
    const [subdepartment, SetSubDepartment] = useState([])
    const GetSubDepartment = async () => {
        try {
            const response = await axiosConfig.get(`/masters/subdepartments/`)
            SetSubDepartment(response.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const [TariffType, setTarifftype] = useState([])
    const [count, setCount] = useState(0)
    const HandleEdittariff = async () => {
        try {
            const response = await axiosConfig.get(`/masters/tarifftype/`)
            setTarifftype(response.data)
            setCount(1)
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption.value, name)
        setTariffChange({ ...TarifChange, tarifftype: selectedOption.value })
    }
    useEffect(() => {
        GetSubDepartment()
        GetPriorities()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View/Edit Tariff Card</h5>
                    <NavLink to="/dashboard/masters/tariffcard" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="clientbill-info">
                    <div className="clientinformation">
                        <h5 className="total-amount">Client Details:</h5>
                        <div className="row">
                            <div className="col-12">
                                <p><FaRegHospital /> #{TariffEditData.id && TariffEditData.id} {TariffEditData.tariffcategory_code && TariffEditData.tariffcategory_code}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
                {EditPopupType != "" &&
                    <EditPopupModal EditPopupType={EditPopupType} setEditpopupType={setEditpopupType} Message="Record Updated Successfully" />
                }
            </DashboardLayout>
        </>
    )

}
export { TariffCardEdit }