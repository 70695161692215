import React, { useEffect, useState } from "react";
import { DashboardLayout,DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
const ScreenFunctionList = () => {
    let navigate = useNavigate();
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()

    const [Data, SetData] = useState([])
    // const GetData = async () => {
    //     try {
    //         const responce = await axiosConfig.get("/masters/screenfunction/")
    //         SetData(responce.data)
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }

    // }
    useEffect(() => {
        // GetData();
    }, [])

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Screen Code",
            selector: row => row.code,
            sortable: true
        },
        {
            name: "Screen Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Screen Description",
            selector: row => row.description,
            sortable: true
        },
        {
            name: "module Screen",
            selector: row => row.modulescreen_name,
            sortable: true
        },
         {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async() => {
                    return navigate(`/dashboard/masters/screenfunctions/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/screenfunction/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        // GetData()
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                return (
                    <>
                         <div className="row" >
                            <div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Role Module Permissions</h5>
                    <NavLink to="/dashboard/masters/rolemodulepermission/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                
                <DataTable columns={columns} data={Data} pagination fixedHeader /> */}
                <div className="mt-5">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page is work in progress</p>
                </div>
                
            </DashboardLayout>
        </>
    )
}
export { ScreenFunctionList }