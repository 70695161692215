import React, { useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const CountryAdd = () => {
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const MastersModal = Masters.Country
    const [Error, SetError] = useState("")
    const formSubmit = (Data) => {
        console.log(Data)
            SetError([])
            axiosConfig.post("/masters/countries/", Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                let errorData={}
                console.log(errorData)
                SetformErrorData(errorData)
                   if(error.response.data.country_name[0]==="Enter a valid country name."){
                        errorData.country_name="Enter a valid country name."
                       SetError("Enter a valid country name.")
                  }
                  if(error.response.data.country_name[0]==="This field must be unique."){
                    errorData.country_name="Country Already Exists."
                   SetError("Country Already Exists.")

                  }
                  if(error.response.data.country_name[0]==="This field may not be blank."){
                   SetError("Country may not be blank.")

                  }
                setpopupType("error")
            })
    }
    console.log(formerrorData)
    const error={CountryName:Error.countryName}
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Country</h5>
                    <NavLink to="/dashboard/masters/countries" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/countries"/>
                }
            </DashboardLayout>
        </>
    )

}
export { CountryAdd }