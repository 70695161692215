import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const ReferenceDoaminEdit = () => {
    const[Error,SetError]=useState("")
    const [popupType, setpopupType] = useState("");
    const MastersModal=Masters.ReferenceDomain
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/referencedomain/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                    code:error.response.data.code||["null"]
                    })
                    console.log(ErrorData[0].code)
                    if(ErrorData[0].code[0]==="reference domain with this code already exists."){
                        SetError("Code Already Exists")
                    }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/referencedomain/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Domain {id}</h5>
                    <NavLink to="/dashboard/masters/domains" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/domains" />
                }
            </DashboardLayout>
        </>
    )

}
export {ReferenceDoaminEdit}