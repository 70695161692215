import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import RolePermission from "../Components/DashboardLayout/RolePermissionData";
import Select from 'react-select';
const UsersEdit = () => {
    const MastersModal = Masters.User
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const [roledata, setRoleData] = useState({ permission: false })
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.put(`/account/users/${id}/`, Data, { is_active: "yes" }).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            let formData = []
            formData.push({
                email: error.response.data.email || ["null"],
                mobileno: error.response.data.mobileno || ["null"],
            })
            if (formData[0].email[0] === "user with this email address already exists.") {
                SetError("Email Already Exists")
            }
            if (formData[0].mobileno[0] === "user with this mobileno already exists.") {
                SetError("Mobile Number Already Exists")
            }
            setpopupType("error")
            console.log(formData)
        })
    }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    console.log(Data.role_name)
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/account/users/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }


    }
    const [DefaultRole, SetDefaultrole] = useState({})
    const roleModalPemrission = async () => {
        try {
            const responce = await axiosConfig.get(`/account/roleModulepermission/?role=${id}`)
            responce.data.forEach(responce => {
                console.log(responce)
                DefaultRole[responce.module_permission.modulescreen.name] = { read: responce.module_permission.read, write: responce.module_permission.write, edit: responce.module_permission.edit, delete: responce.module_permission.delete }
                SetDefaultrole({ ...DefaultRole, [responce.module_permission.modulescreen.name]: { read: responce.module_permission.read, write: responce.module_permission.write, edit: responce.module_permission.edit, delete: responce.module_permission.delete } })
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    const [pmsdata, setpmsdata] = useState([])
    const [RolePermission, setRolePermission] = useState([])
    const Rolepermission = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/module/`)
            setpmsdata(responce.data)
            let data = []
            responce.data.forEach(res => {
                res.sub_module.forEach(submodule => {
                    console.log(submodule)
                    submodule.module_screen.forEach(module => {
                        const existingIndex = data.findIndex(find => find.role === id)
                        if (existingIndex === -1) {
                            console.log(DefaultRole)
                            data.push({
                                role: id, "module_permission": [{
                                    "modulescreen": module.id,
                                    read: "False",
                                    write: "False",
                                    edit: "False",
                                    delete: "False"
                                }]
                            })
                        }
                        else {
                            data[existingIndex]['module_permission'].push({
                                "modulescreen": module.id,
                                read: "False",
                                write: "False",
                                edit: "False",
                                delete: "False"
                            })
                        }
                    })
                })
                setRolePermission(...data)
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    console.log(RolePermission)
    useEffect(() => {
        GetData();
        Rolepermission();
        roleModalPemrission();
    }, [])
    const HandleChange = (e, headid, titleid, title) => {
        const { name, value } = e.target
        console.log(name, value, headid, titleid, title)
        const Index = RolePermission.module_permission.findIndex(find => find.modulescreen === titleid)
        console.log(Index)
        const Updatata = RolePermission
        Updatata.module_permission[Index][name] = value
        console.log(Updatata)
        setRolePermission(Updatata)
    }
    const formSubmitRolePermission = () => {
        axiosConfig.post(`/account/roleModulepermission/${id}/role_permission/`, RolePermission).then(res => {
            console.log(res)
        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit User {id}</h5>
                    <NavLink to="/dashboard/masters/users" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="accordion-container">
                    <div className="accordion" id="accordionClient">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <h5 className="dashboard-subtitle">View/Edit Users</h5>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} />
                                </div>
                            </div>
                        </div>
                         <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <h5 className="dashboard-subtitle">Users Permissions</h5>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse  show" aria-labelledby="headingTwo" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    {/* <div className="d-flex justify-content-between">
                                        <input type="text" className="form-control w-25" name="search" />
                                        <div>
                                            <button type="submit" className="btn btn-primary" onClick={formSubmitRolePermission}>Save</button>
                                        </div>
                                    </div>
                                    <div className="row role-main-heading">
                                        <div className="col-4">
                                            <p className="role-heading">Permissions</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">Read</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">Write</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">update</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">Delete</p>
                                        </div>
                                    </div>
                                    {
                                        pmsdata.map((head, id) => {
                                            return (
                                                <div key={id}>
                                                    <h5 className="dashboard-subtitle">{head.name}</h5>
                                                    {
                                                        head.sub_module.map(res => {
                                                            return (
                                                                <div key={res.id}>
                                                                    <div className="role-menu-header">
                                                                        <h5 className="dashboard-subtitle">{res.name}</h5>
                                                                    </div>
                                                                    <div className="role-actions">
                                                                        {
                                                                            res.module_screen.map((title, i) => {
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <div className="row">
                                                                                            <div className="col-4">
                                                                                                <p className="role-heading">{title.name}</p>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                                <div class="form-check form-check-inline">

                                                                                                    <input className="form-check-input role-check" name="read" defaultChecked={DefaultRole[title.name] && DefaultRole[title.name].read} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e, res.id, title.id, title.name)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                                <div class="form-check form-check-inline">
                                                                                                    <input className="form-check-input role-check" name="write" defaultChecked={DefaultRole[title.name] && DefaultRole[title.name].write} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e, res.id, title.id, title.name)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                                <div class="form-check form-check-inline">
                                                                                                    <input className="form-check-input role-check" name="edit" defaultChecked={DefaultRole[title.name] && DefaultRole[title.name].edit} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e, res.id, title.id, title.name)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                                <div class="form-check form-check-inline">
                                                                                                    <input className="form-check-input role-check" name="delete" defaultChecked={DefaultRole[title.name] && DefaultRole[title.name].delete} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e, res.id, title.id, title.name)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            )
                                        })
                                    } */}
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" Message="Users Upfdated Successfully " />
                }
            </DashboardLayout>
        </>
    )

}
export { UsersEdit }