import React, { useEffect, useState, useRef } from "react";
import { DeletePopupModal, PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import DataTable from "react-data-table-component";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import Masters from "../Components/masters.json"
import { FormParser } from "../Components";
const Certificates = ({ data, HandleCount }) => {
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Data, SetData] = useState([])
    const [tableData, SetTableData] = useState([])
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const masterModal = Masters.Certificate
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/account/certificates/?user=${data.id}`);
            SetTableData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const formSubmit = (Data) => {
        const formData=new FormData()
        formData.append("certificate_type", Data.certificate_type);
        formData.append("file", Data.file);
        if(!Data.id){
            formData.append("user", data.id);
        }
        if (Data.id) {
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            axiosConfig.patch(`/account/certificates/${Data.id}/`, Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            })
        }
        else {
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            axiosConfig.post("/account/certificates/", formData).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            })
        }


    }
    function extractDate(dateTimeString) {
        const dateOnly = dateTimeString.split('T')[0];
        return dateOnly;
    }
    useEffect(() => {
        GetData()
    }, [data.id])
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => i + 1,
            sortable: true,
        },
        {
            name: "Certificate Type",
            selector: "certificate_type_name",
            cell: (row) => `${row.certificate_type.code}`,
            sortable: true,

        },
        {
            name: "created Date",
            selector: "createdDate",
            cell: (row) => extractDate(row.certificate_type.cdate),
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "ACTIONS",
            selector: (row) => {
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/account/certificates/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })

                }
                const HandleEdit = () => {
                    console.log(row)
                    SetData({
                        id: row.id, "certificate_type": row.certificate_type.id,
                        "file": row.file
                    })
                }
                return (
                    <>
                        <div className="row text-center" onClick={HandleEdit} >
                            <div className="col-4" >
                                <MdOutlineEdit className="dashboard-tabe-data-actions" />
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            },
        }
    ]
    return (
        <div className="row prefrence-paddin">
            <div className="col-6 table-padding">
                <DataTable columns={columns} data={tableData}
                    customStyles={tableCustomStyles}
                >
                </DataTable>
            </div>
            <div className="col-6">
                <FormParser modelObject={masterModal} buttonTitle="Upload" formSubmit={formSubmit} formData={Data} />
            </div>
            {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </div>
    )
}
export { Certificates }