import React, { useEffect, useState, useRef } from "react";
import { MdDeleteOutline, MdOutlineEdit, } from "react-icons/md"
import { DeletePopupModal } from "../Components";
import { AiOutlineEye, AiOutlineCloudDownload } from "react-icons/ai"
import { IoMdDownload } from "react-icons/io"
import 'bootstrap/dist/css/bootstrap.min.css';
import { DashboardLayout, Pagination, PopupModal } from "../Components";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import Dropdown from 'react-bootstrap/Dropdown';
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
import { AiOutlineDownload } from "react-icons/ai"
import Select, { components } from "react-select";
import axios from 'axios';
import { MdOutlineRefresh,MdEditNote } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import { BiMailSend } from "react-icons/bi"
import { TbFileInvoice } from "react-icons/tb"
import { SiApollographql } from "react-icons/si"
import { RiFileExcelLine } from "react-icons/ri"
import {BsFilePdf} from "react-icons/bs"
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
const Invoicestwo = ({ Code }) => {
    console.log(Code)
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    const [popupType, setpopupType] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    let month = searchParams.get("month")
    const [showMenu, setShowMenu] = useState(false);
    const [actionPermisson, SetActionPermission] = useState([])
    const [DataDownload, SetDataDownload] = useState([])
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [clientId, SetClientId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [tableId, setTableId] = useState()
    const [lgShow, setLgShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const[readOnly,setreadOnly]=useState(true)
    const [details, setDetails] = useState({
        Total_Clients: 0,
        Total_Cases: 0,
        Total_Price: 0.0,
    });
    const [currentColumn, setCurrentColumn] = useState(null);
    const [check, setCheck] = useState(false)
    const[ExportData,setExportData]=useState([])
    const [currentDirection, setCurrentDirection] = useState(null);
    console.log(month)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Load, setload] = useState(false);
    const [Data, SetData] = useState([])
    const [search, SetSearch] = useState({
        search: "",
        month: ""
    })
    const [select, Setselct] = useState({
        month: ""
    })
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const [Datafilter, SetDatafilter] = useState([])
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`;
        setDataTableHeight(newHeight);
    };
    const [page, NextPage] = useState(1)
    console.log(page)
    const GetData = async () => {

        setLoading(true)
        try {
            console.log(page)
            const responce = await axiosConfig.get(`/client/client-invoices/?month__startswith=${month}&page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            SetData(responce.data.results);
            setLoading(false);
            console.log(responce.data, 'datainvoice')
            setTotalRows(responce.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const HandleExportData = async () => {
        const response = await axiosConfig.get(`/client/client-invoices/?month__startswith=${month}&page=1&page_size=20`)
        console.log(response)
        setExportData(response.data.results)
        let count = Math.ceil(response.data.count /20)
        let number = []
        console.log(count)
        for (let i = 1; i < count + 1; i++) {
          if (i != 1) {
            number.push(i)
          }
        }
        setTimeout(async function () {
          number.forEach(async (page) => {
            try {
              const response = await axiosConfig.get(`/client/client-invoices/?month__startswith=${month}&page=${page}&page_size=20`)
              setExportData(prevData => [...prevData, ...response.data.results])
              setLoading(false)
            }
            catch (error) {
              console.log(error)
              setLoading(false)
            }
          })
        }, 500)
      }

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
    useEffect(() => {
        HandleExportData();
        GetData();
        PermitData();
        GetDeatail();
        calculateDataTableHeight();
    }, [currentPage, RowPerPage, currentColumn, currentDirection, month]);

    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value, 'name,value')
        axiosConfig.get(`/client/client-invoices/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        month = value
        setSearchParams({ 'month': value });
        Setselct({ ...select, "month": value })
        GetData();
    }
    const GetDeatail = async () => {
        if (month && month.length > 0) {

            setLoading(true)
            try {
                const responce = await axiosConfig.get(`client/total_count/${month}/`);
                console.log(responce.data)
                setDetails(responce.data);
                setLoading(false);
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    const [clients, setclients] = useState({})
    const handleEditchange = (e) => {
        const { name, value } = e.target
        setclients({ ...clients, [name]: value })
    }
    const EditClient = async (id) => {
        setload(true)
        try {
            const invoicePromise = axiosConfig.get(`/client/send_invoice/${id}/`);
            const patchPromise = axiosConfig.patch(`/client/clients/${clients.id}/`, clients);
            const [patchResponse, invoiceResponse] = await Promise.all([invoicePromise, patchPromise]);
            setShowToast(true);
            setLgShow(false);
            setTimeout(() => {
                setload(false)
            }, 5000)
        } catch (error) {
            console.error("Error:", error);
        }
        console.log(id);
    }

    const handleDownloadExcel = () => {
        const header = ["S.NO", "client code",
            "CLIENT NAME",
            "Inst.Group",
            "INVOICE NO",
            "NO OF CASES",
            "DISCOUNT",
            "NET AMOUNT",
            "client type",
            "TOTAL AMOUNT"];
            console.log(ExportData)
        const body3 = ExportData && ExportData.map((res, index) => [index + 1, res.client_code, res.client_name, res.client_alias_name, res.invoice_id, res.quantity, res.discount, res.total_net_amount, res.client_type, res.total_amount])
        downloadExcel({
            fileName: `invoices.xls`,
            sheet: `invoices.xls`,
            tablePayload: {
                header,
                body: body3,
            },
        });
    }


    const handleAllCasesAmountDownload = async () => {
        try {
            const response = await axiosConfig.get(`/client/client_cases_all_bills/${month}/`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = ' Detailed_Client_Bills.xlsx';
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }

    };
    const handleOptionSelect = async (event, id, client, client_name, client_alias_name,email,additional_email) => {
        if (event === "3") {
            await axiosConfig.get(`/client/pdf/${id}/`).then(response => {
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${client_name}.pdf`);
                document.body.appendChild(link);
                link.click();


            })
                .catch(error => {
                    console.error('There was an error downloading the PDF', error);

                });
        }
        else if (event === "4") {
            await axiosConfig.get(`/client/pdf/${id}/?logo=yes`).then(response => {
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${client_name}.pdf`);
                document.body.appendChild(link);
                link.click();


            })
                .catch(error => {
                    console.error('There was an error downloading the PDF', error);
                });
        }
        else if (event === "6") {
            setLgShow(true)
            setclients({
                email:email,
                additional_email:additional_email,
                client:client
            })
        }
        else if (event === "5") {
            let Datainvoice = []
            try {
                const response = await axiosConfig.get(`/client/client-invoices/${id}/`)
                try {
                    const xlsxResponse = await axiosConfig.get(response.data.xlsx_path, {
                        responseType: 'blob',
                    });
                
                    const url = window.URL.createObjectURL(xlsxResponse.data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${client_name}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('There was an error downloading the XLSX file', error);
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    };

    // const tableCustomStyles = {
    //     rows: {
    //         style: {},
    //     },
    //     cells: {
    //         style: {
    //             justifyContent: "center"
    //         },
    //     },
    //     headCells: {
    //         style: {
    //             color: "#5C5C5C",
    //             justifyContent: "center",
    //             color: "rgb(33,37,41)",
    //             fontSize: "12px",
    //             fontWeight: 500,
    //             lineHeight: "28px",
    //             padding: "8px 12px",
    //         },
    //     },
    // };
    const HandleMouse = (row) => {
        setTableId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const handleClose=()=>{
        setLgShow(false)
        setreadOnly(true)
        setclients({})
    }
    const ClinetUpdate=async()=>{
        await axiosConfig.patch(`/client/clients/${clients.client}/`, {email:clients.email,additional_email:clients.additional_email}).then(res=>{
            console.log(res)
            setreadOnly(true)
        }).catch(error=>{
            console.log(error)
        })
    }
    const columns = [
        {
            name: "Client Code",
            selector: "client_code",
            cell: (row, i) => `${row.client_code}`,
            sortable: true,
            width:"100px"
        },
        {
            name: "Client Name",
            selector: "client_name",
            cell: (row) => `${row.client_name}`,
            sortable: true,
            left: true,
        },
        {
            name: "Inst.Group",
            selector: "client_alias_name",
            cell: (row) => `${row.client_alias_name}`,
            sortable: true,
            left: true
        },
        {
            name: "Address",
            selector: "address",
            cell: (row) => `${row.address}`,
            sortable: true,
        },
        {
            name: "Invoice No",
            selector: "invoice_id",
            cell: (row) => `${row.invoice_id}`,
            sortable: true,
        },
        {
            name: "No of Cases",
            selector: "quantity",
            cell: (row) => `${row.quantity}`,
            sortable: true,
            width:"80px"

        },
        {
            name: "Total Amount",
            selector: "total_amount",
            cell: (row) => {
                return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(parseFloat(row.total_amount).toFixed(2));
            },
            sortable: true,
            grow: 1,
            right: true
        },
        {
            name: "Discount",
            selector: "discount",
            cell: (row) => {
                return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(parseFloat(row.discount).toFixed(2));
            },
            sortable: true,
            width:"80px"

        },
        {
            name: "Net Amount",
            selector: "total_net_amount",
            cell: (row) => {
                return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(parseFloat(row.total_net_amount).toFixed(2));
            },
            sortable: true,
            right: true,
            width:"100px"

        },
        {
            name: "ACTIONS",
            width: "210px",
            selector: (row) => {
                const HandleEdit = (client) => {
                    return navigate(`/dashboard/billing/client-final-billss/${client}/view/${month}`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id,client) => {
                    setload(true)
                    SetAlertDeletePopupType("error")
                    console.log(id)
                    try {
                        const invoicePromise = axiosConfig.get(`/client/send_invoice/${id}/`);
                        setShowToast(true);
                        setLgShow(false);
                        setTimeout(() => {
                            setload(false)
                        }, 5000)
                    } catch (error) {
                        console.error("Error:", error);
                    }
                    console.log(id);

                }
                const HandleView = async (id) => {
                    try {
                        const response = await axiosConfig.get(`/client/pdf/${id}/`);
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const newTab = window.open();
                        newTab.location.href = url;
                    } catch (error) {
                        console.log(error);
                    }
                };
                const shouldShowActions = tableId === row.id
                return (
                    <>
                        {
                            shouldShowActions && (
                                <div className="row align-items-center" >
                                    {/* {actionPermisson && actionPermisson.read && <div className="" onClick={() => HandleView(row.id)}>
                        <AiOutlineEye />
                    </div>} */}
                                    <div className="d-flex align-items-center gap-3">
                                        {actionPermisson && actionPermisson.edit && <div onClick={() => HandleEdit(row.client)}>
                                            <MdOutlineEdit className="dashboard-tabe-data-actions"/>
                                        </div>}
                                        <div onClick={() => handleOptionSelect("6", row.id, row.client, row.client_name, row.client_alias_name,row.email,row.additional_email)}>
                                            <BiMailSend className="dashboard-tabe-data-actions"/>
                                        </div>
                                        <div onClick={() => handleOptionSelect("3", row.id, row.client, row.client_name, row.client_alias_name)}>
                                            <TbFileInvoice className="dashboard-tabe-data-actions"/>
                                        </div>
                                        <div onClick={() => handleOptionSelect("4", row.id, row.client, row.client_name, row.client_alias_name)}>
                                            <SiApollographql className="dashboard-tabe-data-actions"/>
                                        </div>
                                        <div onClick={() => handleOptionSelect("5", row.id, row.client, row.client_name, row.client_alias_name)}>
                                            <RiFileExcelLine className="dashboard-tabe-data-actions"/>
                                        </div>
                                        <div onClick={() => HandleView(row.id)}>
                                            <BsFilePdf className="dashboard-tabe-data-actions"/>
                                        </div>
                                    </div>
                                    {
                                        lgShow &&
                                        <Modal
                                            size="lg"
                                            show={lgShow}
                                            onHide={handleClose}
                                            aria-labelledby="example-modal-sizes-title-lg"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title id="example-modal-sizes-title-lg">
                                                    {row.client_name}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {Load ? (
                                                    <div className="loader-container">
                                                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Email:</label>
                                                                    <input type="text" className="form-control" placeholder="Email" name="email" defaultValue={clients.email} disabled={readOnly} onChange={handleEditchange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Additional Email:</label>
                                                                    <input type="text" className="form-control" placeholder="Email" name="additional_email" defaultValue={clients.additional_email} disabled={readOnly} onChange={handleEditchange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                       {readOnly?
                                                       <div className="text-end mx-4">
                                                            <button type="submit" className="btn btn-default pull-left" onClick={()=>setreadOnly(false)}>Edit Email's <MdEditNote /></button>
                                                        </div>: <div className="text-end mx-4">
                                                            <button type="submit" className="btn btn-primary" onClick={ClinetUpdate}>Save</button>
                                                        </div>}
                                                       {readOnly &&<div className="text-end">
                                                            <button type="submit" className="btn btn-primary" onClick={DeleteAlert}>Send Invoice</button>
                                                        </div>}
                                                        </div>
                                                    </div>
                                                )}

                                            </Modal.Body>
                                        </Modal>
                                    }
                                    {
                                        AlertDeletePopupType === "success" &&
                                        <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} clientId={clientId} message="Are You Sure You Want to mail invoice"/>
                                    }
                                </div>
                            )
                        }
                    </>
                )

            },
        }
    ]
    console.log(dataTableHeight)
    return (
        <>
            <DashboardLayout>
                {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Final Bills</h5>
                </div> */}
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                        <input type="month" name="month" className="form-control month-selector" value={month} onChange={HandleSelect} />
                    </div>
                    <div className="table-responsive table-resp">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page, totalRows) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            paginationServer
                            fixedHeader
                            customStyles={tableCustomStyles}
                            fixedHeaderScrollHeight={dataTableHeight}
                            selectableRows
                            onSelectedRowsChange={({ selectedRows }) =>
                                console.log(selectedRows)
                            }
                            selectableRowsHighlight
                            highlightOnHover
                            subHeader
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            subHeaderComponent={
                                <div className="serach-class">
                                    <h5 className="dashboard-subtitle1">NO.Of Clients: <br></br> IND:{details.Total_Clients_ind}<br></br>INT:{details.Total_Clients_int}</h5>
                                    <h5 className="dashboard-subtitle1">NO.Of Cases:  <br></br>IND:{details.Total_Cases_ind}<br></br>INT:{details.Total_Cases_int}</h5>
                                    <h5 className="dashboard-subtitle1">Total Price: <br></br>IND:{details.Total_Price_ind}<br></br>INT:{details.Total_Price_int}</h5>
                                    <button type="submit" className="btn btn-primary" onClick={handleDownloadExcel}>Export</button>
                                    <button type="submit" className="btn btn-primary" onClick={handleAllCasesAmountDownload}>Export Detailed Invoices</button>
                                    <input className="form-control w-25" onChange={HandleSearch} />
                                </div>
                            } />
                    </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
                <Toast onClose={() => setShowToast(false)} className="toast-main" show={showToast} animation={false} autohide delay={3000}>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto"></strong>
                    </Toast.Header>
                    <Toast.Body>PDF and XLSX Sent Successfully</Toast.Body>
                </Toast>
            </DashboardLayout>
        </>
    )
}
export { Invoicestwo }