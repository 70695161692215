import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate, useParams,useNavigation } from "react-router-dom";
import { GrNewWindow } from "react-icons/gr"
import { RiUserFollowLine } from "react-icons/ri"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"
import { AiOutlineUserAdd, AiOutlineMessage, AiOutlineFileText } from "react-icons/ai"
import { MdArrowBackIosNew, MdOutlineNavigateNext } from "react-icons/md"
import { GrGallery } from 'react-icons/gr'
import { TiAttachment } from "react-icons/ti"
import axiosConfig from "../Service/axiosConfig"
const WorklistExpandComponent = () => {
    let { id } = useParams()
    let navigate=useNavigate();
    const[Data,setData]=useState({})
    const[count,setCount]=useState(parseInt(id))
    const[Totalcount,setTotalCount]=useState(0)
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/cases/case/${count}/`)
            console.log(responce)
            setData(responce.data)
        }
        catch (error) {
            console.log(error)
            
        }

    }
    const Gettotalcount = async () => {
        try {
            const responce = await axiosConfig.get(`/cases/case/`)
            console.log(responce)
            setTotalCount(responce.data.count)
        }
        catch (error) {
            console.log(error)
            
        }

    }
    function formatTimestamp(initialTimestamp) {
        if (!initialTimestamp) {
          return null;
        }
      
        const timestampDate = new Date(initialTimestamp);
      
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
      
        const dayOfWeek = daysOfWeek[timestampDate.getDay()];
        const month = months[timestampDate.getMonth()];
        const day = timestampDate.getDate();
        const time = timestampDate.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
      
        const currentDate = new Date();
        const minutesDifference = Math.floor((currentDate - timestampDate) / 60000); // Difference in minutes
      
        const formattedMinutes = String(minutesDifference % 60).padStart(2, '0');
        const formattedHours = String(Math.floor(minutesDifference / 60)).padStart(2, '0');
      
        const formattedTime = `${dayOfWeek}, ${day} ${month}, ${time} (${formattedHours}:${formattedMinutes} mins)`;
      
        return formattedTime;
      }
      
      
    useEffect(()=>{
        if (count !== Totalcount) {
            GetData();
        }
      if(!Totalcount){
        Gettotalcount()
        console.log(count,Totalcount)
      }
      navigate(`/dashboard/worklistExpand/${count}`)
    },[count])
    const HandleCount=()=>{
        if(count !==Totalcount){
            setCount(count+1)
        }
    }
    const HandleDecrementCount=()=>{
        if(count !==0){
            setCount(count-1)
        }
    }
    return (
        <>
            <DashboardLayout>
                <div className="tablescroll-clientbill table-main-worklist">
                    <div className="row">
                        <div className="col-6">
                            <div className="dashboard-worklist-icon">
                                <div className="gap-icon-worklist">
                                    <span className="cursor-pointer"><FiArrowLeft  onClick={HandleDecrementCount}  className="cursor-pointer"/></span>
                                    <span className="">
                                        <RiUserFollowLine />
                                    </span>
                                    <span className="">
                                        <AiOutlineUserAdd />
                                    </span>
                                    <span className="">
                                        <AiOutlineMessage />
                                    </span>
                                </div>
                                <div className="gap-icon-worklist">
                                    <span className="">
                                        <RiUserFollowLine />
                                    </span>
                                    <span className="">
                                        <AiOutlineUserAdd />
                                    </span>
                                    <span className="">
                                        <AiOutlineMessage />
                                    </span>
                                </div>
                                <div className="gap-icon-worklist-end">
                                    <span className="">
                                        <RiUserFollowLine />
                                    </span>
                                    <span className="">
                                        <AiOutlineUserAdd />
                                    </span>
                                    <span className="">
                                        <AiOutlineMessage />
                                    </span>
                                    <span className="cursor-pointer">
                                        <FiArrowRight onClick={HandleCount} className="cursor-pointer"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 next-pagination">
                            <spa>{count} of {Totalcount}</spa>
                            <svg
                                className="cursor-pointer"
                                onClick={HandleDecrementCount}
                                aria-label="Previous Page"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                                role="presentation"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
                            </svg>
                            <svg
                               className="cursor-pointer"
                                onClick={HandleCount}
                                aria-label="Next Page"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                                role="presentation"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12l-4.58 4.59z" />
                            </svg>
                        </div>
                    </div>
                    <div className="row client-worklist-main">
                        <div className="col-6">
                        <div className="row">
                        <div className="col-6">
                            <p className="text-style-head">Institution Group</p>
                            <p className="text-style-head">Case ID / Accession Number</p>
                            <p className="text-style-head">Patient Name</p>
                            <p className="text-style-head">Age</p>
                            <p className="text-style-head">Sex</p>
                            <p className="text-style-head">Modality</p>
                            <p className="text-style-head">Study Description</p>
                            <p className="text-style-head">Priority</p>
                            <p className="text-style-head">Set As</p>
                            <p className="text-style-head">Case Upload Date / Time </p>
                        </div>
                        <div className="col-2">
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                            <p className="text-style-head-list">:</p>
                        </div>
                        <div className="col-4">
                            <p className="text-style-head-list">{Data.instutution_name}</p>
                            <p className="text-style-head-list">{Data.patient_id}</p>
                            <p className="text-style-head-list">{Data.patient_name}</p>
                            <p className="text-style-head-list">{Data.age}</p>
                            <p className="text-style-head-list">{Data.gender?Data.gender:"Gender"}</p> 
                            <p className="text-style-head-list">{Data.modality_name}</p>
                            <p className="text-style-head-list">{Data.study_description}</p>
                            <p className="text-style-head-list">{Data.priority_name}</p>
                            <p className="text-style-head-list">{Data.status}</p>
                            <p className="text-style-head-list">{formatTimestamp(Data.study_completed_date_time)}</p>
                        </div>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="dashboard-worklist-icon">
                                <div className="align-ments">
                                    <div className="images-table"><span className="icon-image"><GrGallery /></span>Total images received |<span className="images-count">200</span></div>
                                </div>
                                <div className="images-icon-attch">
                                    <div className="images-table-attach"><span className="icon-image"><TiAttachment /></span>Attachments</div>
                                    <div className="images-table-attach"><span className="icon-image"><AiOutlineFileText /></span>History</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { WorklistExpandComponent }