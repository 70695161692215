import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const StateEdit = () => {
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.State
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/states/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                    state_name:error.response.data.state_name||["null"],
                    country_id:error.response.data.country_id||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].state_name[0]==="already exists"){
                SetError("State Already Exists")
            }
            if(ErrorData[0].state_name[0]==="Enter a valid state name."){
                SetError("Enter a valid state name.")
            }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/states/${id}/`)
            console.log(responce.data)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit State {id}</h5>
                    <NavLink to="/dashboard/masters/states" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/states"/>
                }
            </DashboardLayout>
        </>
    )

}
export {StateEdit}