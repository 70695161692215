import React,{useState,useEffect} from "react";
import Masters from "../Components/masters.json"
import { DashboardLayout, DeletePopupModal,PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig"
const BasicInformation=({data,HandleCount,setCount ,count})=>{
  const masterModal=Masters.RadiologistEdit
  const [Error, SetError] = useState("")
  const[popupType,setpopupType]=useState("")
  const [loading, setLoading] = useState(false);
  const [propData,setPropData]=useState({
    first_name:data.first_name,
    last_name:data.last_name,
    email:data.email,
    mobileno:data.mobileno,
    profile_image:data.profile_image,
    about:data.about,
    address:data.address
  })
  const formSubmit = (Data) => {
    console.log(Data)
        SetError([])

        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        const formData=new FormData()
        formData.append("first_name", Data.first_name);
        formData.append("last_name", Data.last_name);
        formData.append("email", Data.email);
        formData.append("sort", Data.sort);
        formData.append("mobileno", Data.mobileno);
        formData.append("profile_image", Data.profile_image);
        formData.append("about", Data.about);
        formData.append("address", Data.address);
        axiosConfig.patch(`/account/users/${data.id}/`,formData).then(res => {
            console.log(res)
            data["first_name"]=res.data.first_name
              data["last_name"]=res.data.last_name
              data["email"]=res.data.email
              data["mobileno"]=res.data.mobileno
              data["profile_image"]=res.data.profile_image
              data["about"]=res.data.about
              data["address"]=res.data.address
            setpopupType("success")
            HandleCount(2)

        }).catch(error => {
            setpopupType("error")
        })
}
    return(
        <div>
          <FormParser modelObject={masterModal} formData={propData} buttonTitle="Save"   formSubmit={formSubmit}/>
          {popupType != "" &&
         <PopupModal popupType={popupType} setpopupType={setpopupType}/>
     }
        </div>
    )
}
export {BasicInformation}