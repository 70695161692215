import React,{useState,useEffect} from "react";
import Masters from "../Components/masters.json"
import { DashboardLayout, DeletePopupModal,PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig"
const ResetPassword=({data,HandleCount})=>{
    const masterModal=Masters.ResetPassword
    const [Error, SetError] = useState("")
    const[popupType,setpopupType]=useState("")
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const formSubmit = (Data) => {
        if(Data==="Back"){
            HandleCount(5)
        }
        else{
            axiosConfig.put(`/account/users/${data.id}/password_reset/`, Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                setpopupType("error")
            })
        }
    }

    return(
        <div>
            <FormParser modelObject={masterModal} formData={data} formSubmit={formSubmit} buttonTitle="Save" buttonType={true}/>
            {popupType != "" &&
                    <PopupModal popupType={popupType} setpopupType={setpopupType}/>
                }
        </div>
    )
}
export {ResetPassword}