import React, { useEffect, useState ,useRef} from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import {MdOutlineRefresh} from "react-icons/md"
import {CiMenuKebab} from "react-icons/ci"
const CityList = ({Code}) => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const[actionPermisson,SetActionPermission]=useState([])
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const[tableId,setTableId]=useState()
    let navigate = useNavigate();
    const[check,setCheck]=useState(false)
    const [search, SetSearch] = useState({
        search: ""
    })
    const [Datafilter, SetDatafilter] = useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/masters/cities/?page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
            } catch (error) {
            console.log(error);
            }
    };
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };    
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`; 
        setDataTableHeight(newHeight);
    }; 
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);
   
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/masters/cities/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })

    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const PermitData=()=>{
        const Permmitdata=localStorage.getItem("permission")
        const data=JSON.parse(Permmitdata)
        let moduledata=[]
       const find_data=data.find(res=>res.modulescreen.code===Code)
         SetActionPermission(find_data)
    }
    

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row,i) => (currentPage - 1) * RowPerPage + i  +1,
            sortable: true
        },
        {
            name: "Short Name",
            selector: "short_name",
            cell: (row) => `${row.short_name}`,
            sortable: true
        },
        {
            name: "City Name",
            selector: "city_name",
            cell: (row) => `${row.city_name}`,
            sortable: true
        },
        {
            name: "State",
            selector: "state_name",
            cell: (row) => `${row.state_name}`,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/cities/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`masters/cities/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData()
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const HandleView = () => {
                    return navigate(`/dashboard/masters/cities/${row.id}/edit`);
                }
                const shouldShowActions = tableId === row.id;
                return (
                    <>
                    {
                        shouldShowActions &&(
                            <div className="row text-center" >
                            {actionPermisson&&actionPermisson.read&&<div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleView}/>
                            </div>}
                            {actionPermisson&&actionPermisson.edit&&<div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>}
                            {actionPermisson&&actionPermisson.delete&&<div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                            </div>}
                        </div>
                        )
                    }
    
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
    
            }
        }
    ]
    const HandleMouse=(row)=>{
        setTableId(row.id)
    }
    const HandleRowmouseLeave=()=>{
        setTableId()
    }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View cities</h5>
                    {actionPermisson&&actionPermisson.write&&<NavLink to="/dashboard/masters/cities/add" ><button className="btn btn-white">Add New</button></NavLink>}
                </div>
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                       </div>
                <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page, totalRows) => {
                            setCurrentPage(page);
                            GetData(currentColumn, currentDirection);
                        }}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        fixedHeader
                        onRowMouseEnter={HandleMouse}
                        onRowMouseLeave={HandleRowmouseLeave}
                        customStyles={tableCustomStyles}
                        fixedHeaderScrollHeight={dataTableHeight}
                        selectableRows
                          onSelectedRowsChange={({ selectedRows }) =>
                          console.log(selectedRows)
                        }
                        selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        progressPending={loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                       subHeaderComponent={
                        <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}>
                </DataTable>
            </div>
            </div>
            {/* {Data.length > 0 && <div className="f-copy">
                <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                </p>
            </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { CityList }