import React, { useEffect, useState } from "react";

const Pagination = ({ data, Page, HandlePerPage, HandleNextPage, HandlePrevPage, HandleStartPage, HandleEndPage }) => {
    const [number, setNumber] = useState([30, 45, 50, 55, 60])
    const [option, setOption] = useState({
        select: "30"
    })
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(value)
        HandlePerPage(value)
        setOption({ ...option, [name]: value })
    }
    let numbers = []
    for (let i = 1; i < (data.length / option.select) + 1; i++) {
        numbers.push(i)
    }
    const [count, Setcount] = useState(1)
    const HandleFunction = () => {
        const newCount =1;
        Setcount(newCount)
        HandleStartPage(option.select)

    }

    const HandleFunction1 = () => {
        const newCount = count + 1;
        Setcount(newCount)
        HandleNextPage(option.select, newCount)

    }

    const HandleFunction2 = (Pages) => {
        const newCount = count - 1;
        Setcount(newCount)
        HandleNextPage(option.select, newCount)
    }
    const HandleFunction3 = (Pages) => {
        const newCount =numbers.length;
        Setcount(newCount)
        HandleEndPage(option.select, newCount)
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-end" aria-label="Page navigation example">
                <span className="rowsPerpage">Rows Per Page:</span>
                <select className="serPerpage" name="select" onChange={HandleChange} value={option.select} >
                    {
                        number.map((num, i) => {
                            return (
                                <option key={i} value={num}>{num}</option>
                            )
                        })
                    }
                </select>
                <span className="rowsPerpage">1-{option.select} of {numbers.length}</span>
                <svg
                    className={`pagination-icon ${count === 1 ? 'disabled' : ''}`}
                    onClick={count === 1 ? null : HandleFunction}
                    aria-label="Pagination Icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                >
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                </svg>
                <svg
                    className={`pagination-icon ${count === 1 ? 'disabled' : ''}`}
                    onClick={count === 1 ? null : HandleFunction2}
                    aria-label="Previous Page"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
                </svg>
                <svg
                    className={`pagination-icon ${count === numbers.length ? 'disabled' : ''}`}
                    onClick={count === numbers.length ? null : HandleFunction1}
                    aria-label="Next Page"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12l-4.58 4.59z" />
                </svg>
                <svg
                    className={`pagination-icon ${count === numbers.length ? 'disabled' : ''}`}
                    onClick={count === numbers.length ? null :HandleFunction3}
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z">
                    </path><path fill="none" d="M0 0h24v24H0V0z"></path>
                </svg>
            </div>
        </>
    )
}
export { Pagination }