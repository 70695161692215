import React, { useEffect, useState,useRef } from "react";
import { DashboardLayout, PopupModal } from "../Components";
import { NavLink, useParams,useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { MdOutlineLocationOn, MdOutlineSupportAgent } from "react-icons/md";
import { FaRegHospital } from "react-icons/fa";
import { Button, Dropdown } from 'react-bootstrap';
import {MdOutlineRefresh} from "react-icons/md"
import {CiMenuKebab} from "react-icons/ci"
const AssignTariff = () => {
    let navigate = useNavigate();
    const[loading,setLoading]=useState()
    const[check,setCheck]=useState(false)
    let{id}=useParams()
    const[TariffCatagory,SetTariffCatogory]=useState({
        tariffcategory:""
    })
    const[clientData,SetClientData]=useState([])
    const HandleChange=(selectedOption, name)=>{
        console.log(selectedOption.value,name)
        SetTariffCatogory({...TariffCatagory,[name]:selectedOption.value})
        setLoading(true)
        axiosConfig.get(`/client/clienttariff/?tariffcategory=${selectedOption.value}`).then(res => {
            console.log(res.data)
            setLoading(false)
            SetClientData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const[Data,SetData]=useState([])
    const tariffcategorie = () => {
        axiosConfig.get("/masters/tariffcategories/").then(res => {
            console.log(res.data)
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const[TaariffId,SetTariffId]=useState()
    const GetData = () => {
        axiosConfig.get(`/client/clientagreement/?client=${id}`).then(res => {
            console.log(res.data[0].primary_tariff)
            SetTariffId(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const OnSubmit=(e)=>{
        e.preventDefault()
          const{tariffcategory}=TariffCatagory
        axiosConfig.post(`/client/clients/${id}/assign_tariffcard/`,TariffCatagory).then(res=>{
            console.log(res)
            return navigate(`/dashboard/clients/${id}/tariff`);

        }).catch(error=>{
            console.log(error)
        })

    }
    const [clientsEditData, SetClientEditData] = useState([])
    const clientEditData = () => {
        axiosConfig.get(`/client/clients/${id}/`).then(res => {
            SetClientEditData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        tariffcategorie()
        clientEditData()
    }, [])
    const columns = [
        {
            name: "Id",
            selector: row =>row.id,
            sortable: true,
            },
        {
            name: "Code",
            selector: (row) =>row.alias_code,
            sortable: true,
           
        },
        {
            name: "Name",
            selector: (row) =>row.alias_name,
            sortable: true,
        }, 
        {
            name: "Client Name",
            selector: (row) =>row.client_name,
            sortable: true,
        },
        {
            name: "Night Amount",
            selector: (row) =>row.night_amount,
            sortable: true,
        }, 
        {
            name: "Priority",
            selector: (row) =>row.priority_name,
            sortable: true,
        },
        {
            name: "Service Amount",
            selector: (row) =>row.serice_amount,
            sortable: true,
        },
        {
            name: "Tariff Type",
            selector: (row) =>row.tariff_type,
            sortable: true,
        }, 
    ]
    return (
        <DashboardLayout>
            <>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Assign Tariff</h5>
                     <NavLink to="/dashboard/masters/tariffcard" ><button className="btn btn-white">Manage</button></NavLink> 
                </div>
                <div className="clientbill-info">
                                        <div className="clientinformation">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <p><FaRegHospital /> #{clientsEditData.id && clientsEditData.id} {clientsEditData.client_name && clientsEditData.client_name}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p><MdOutlineLocationOn /> {clientsEditData.email && clientsEditData.email} | {clientsEditData.phone && clientsEditData.phone}</p>
                                                </div>
                                                <div className="col-md-3">
                                                    <p><MdOutlineSupportAgent /> {clientsEditData.address && clientsEditData.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                <form onSubmit={OnSubmit}>
                <div className="form-group">
                <label className="form-label">Tariff Category:</label>
                <Select options={Data&&Data.map((res) =>(
                                                        {"value": res.id, "label": res.tariffcategory_code} 
                                    ))} placeholder="SELECT Tariff Category" onChange={(selectedOption)=>HandleChange(selectedOption, "tariffcategory")}   required/>
                                     </div>
                <button type="submit" className="btn btn-primary">Submit</button>
                </form>
                {loading ? (<div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        </div>) :Data.length === 0 ? <p className="text-center"> There are no records to display</p> :
                                         <div className="tablescroll-clientbill">
                                         <div className="table-header-menu">
                                             <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                                 <Dropdown.Toggle variant="">
                                                     <input
                                                         type="checkbox"
                                                         className="form-check-input"
                                                         onClick={handleCheckboxClick}
                                                     />
                                                 </Dropdown.Toggle>
                                                 <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                                     <Dropdown.Item>Action</Dropdown.Item>
                                                     <Dropdown.Item>Another action</Dropdown.Item>
                                                     <Dropdown.Item>Something else here</Dropdown.Item>
                                                     <Dropdown.Divider />
                                                     <Dropdown.Item>Separated link</Dropdown.Item>
                                                 </Dropdown.Menu>
                                             </Dropdown>
                                             <MdOutlineRefresh className="sidebar-icon-table" />
                                             <CiMenuKebab className="sidebar-icon-table" />
                                              {/* <nav class="pagination-totalpages">
                                                 <span class="hOXYFc">Rows per page:</span>
                                                 <select>
                                                     <option value="10">10</option>
                                                     <option value="20" selected="">20</option>
                                                     <option value="25">25</option>
                                                     <option value="30">30</option>
                                                     <option value="40">40</option>
                                                     <option value="50">50</option>
                                                 </select>
                                                 <span class="fwhfat">1-20 of 22</span>
                                                 <div class="bcYJHc"><button id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="" className="gryXwN"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></button>
                                                     <button id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled="" className="gryXwN"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                                                     <button id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="false" className="gryXwN" fdprocessedid="8wrtk"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                                                     <button id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="false" className="gryXwN" fdprocessedid="mhvkeh"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></button>
                                                 </div></nav> */}
                                                 </div>  
                <div className="table-responsive">
                <DataTable columns={columns} data={clientData} pagination fixedHeader customStyles={tableCustomStyles}/>
            </div></div>}
            </>
        </DashboardLayout>
    )
}
export { AssignTariff }