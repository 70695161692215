import React, { useState, useEffect } from "react";
import Masters from "../Components/masters.json"
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';
const Preference = ({ data, HandleCount }) => {
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState({
        is_dometic: data.is_dometic?data.is_dometic:false,
        is_internation:data.is_internation?data.is_internation:false,
        is_specialist_openion:data.is_specialist_openion?data.is_specialist_openion:false
    })
    const [referencedomainvalue, setreferencedomainvalue] = useState([])
    const [Edit, SetEditData] = useState([])
    const [preference, setpreference] = useState({
        "user": data.id,
        "preference_modalities": [],
        "nopreferences_modalities": [],
        "preference_speciality": [],
        "nopreferences_speciality": []
    })
    let options = [
        { value: 1, label: 'CT' },
        { value: 2, label: 'MR' },
        { value: 3, label: 'DX' },
        { value: 4, label: 'PETCT' },
    ];
    const HandleChange = (e) => {
        const { name, value } = e.target;
        SetData((prevData) => ({
          ...prevData,
          [name]: value.toLowerCase() === "true" ? !prevData[name] : false,
        }));
      };
    console.log(data,Data)
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/masters/referencedomainvalue/?domain=37&domain__code=`);
            setreferencedomainvalue(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }

    };
    const editData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`http://3.7.4.32/account/radiologist/?user=${data.id}`);
            const updatedPreferences = response.data.results.map(element => ({
                id: element.id,
                preference_modalities: element.preference_modalities.map(res => res.id),
                nopreferences_modalities: element.nopreferences_modalities.map(res => res.id),
                preference_speciality: element.preference_speciality.map(res => res.id),
                nopreferences_speciality: element.nopreferences_speciality.map(res => res.id)
              }));
              if (updatedPreferences.length > 0) {
                setpreference(...updatedPreferences);
              }
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const handleSelectChange = (selectedOption, name) => {
        setpreference({...preference ,[name]: [selectedOption.value],})
      };
      
      const handleModalitys = (e) => {
        const { name, value} = e.target;
      
        setpreference((prevPreference) => {
            const updatedArray = [...prevPreference[name]]; 
            if (updatedArray.includes(parseFloat(value))) {
             const index = updatedArray.indexOf(parseFloat(value));
             console.log(index)
              updatedArray.splice(index, 1);
            } else {
              updatedArray.push(parseFloat(value));
            }
        
            return {
              ...prevPreference,
              [name]: updatedArray,
            };
          });
      };
     console.log(preference) 
    const formSubmit = (e) => {
        e.preventDefault();
        const formData=new FormData()
        formData.append("user",data.id)
        preference.preference_modalities.forEach(val=>{
            formData.append("preference_modalities",val)
        })
        preference.nopreferences_modalities.forEach(val=>{
            formData.append("nopreferences_modalities",val)
        })
        preference.preference_speciality.forEach(val=>{
            formData.append("preference_speciality",val)
        })
        preference.nopreferences_speciality.forEach(val=>{
            formData.append("nopreferences_speciality",val)
        })
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if(preference.id){
            axiosConfig.put(`/account/radiologist/${preference.id}/`, formData).then(res => {
                axiosConfig.patch(`/account/users/${data.id}/`, Data).then(res => {
                    console.log(res)
                    setpopupType("success")
                    HandleCount&&HandleCount(6)
                    data["is_dometic"]=res.data.is_dometic
                    data["is_internation"]=res.data.is_internation
                    data["is_specialist_openion"]=res.data.is_specialist_openion
                }).catch(error => {
                    console.log(error)
                    setpopupType(error)
                }).catch(error => {
                    console.log(error)
                    setpopupType("error")
                })
        })
    }
       else{
        axiosConfig.post(`/account/radiologist/`,formData).then(res => {
            axiosConfig.patch(`/account/users/${data.id}/`, Data).then(res => {
                console.log(res)
                setpopupType("success")
                HandleCount&&HandleCount(6)
                data["is_dometic"]=res.data.is_dometic
                data["is_internation"]=res.data.is_internation
                data["is_specialist_openion"]=res.data.is_specialist_openion
            }).catch(error => {
                console.log(error)
                setpopupType(error)
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            })
       })
    }
}
    useEffect(() => {
        GetData()
        editData();
    }, [])
    return (
        <div>
            <div className="prefrence-paddin">
                <div className="row">
                    <div className="col-3">
                        <div class="form-check form-check-inline form-check-lable">
                            <input class="form-check-input ari-checbox" type="checkbox" id="is_dometic" value={true} name="is_dometic" checked={Data.is_dometic} onChange={HandleChange} />
                            <label class="form-check-label" for="is_dometic">Domestic</label>
                        </div>
                    </div>
                    <div className="col-3">
                        <div class="form-check form-check-inline form-check-lable">
                            <input class="form-check-input ari-checbox" type="checkbox" id="is_internation" value={true} name="is_internation" checked={Data.is_internation} onChange={HandleChange} />
                            <label class="form-check-label" for="is_internation">International</label>
                        </div>
                    </div>
                    <div className="col-3">
                        <div class="form-check form-check-inline form-check-lable">
                            <input class="form-check-input ari-checbox" type="checkbox" id="is_specialist_openion" value={true} name="is_specialist_openion" checked={Data.is_specialist_openion} onChange={HandleChange} />
                            <label class="form-check-label" for="is_specialist_openion">Specialist Opinion</label>
                        </div>
                    </div>
                </div>
                <div>
                    <h5 className="dashboard-subtitle">Preferences</h5>
                </div>
            </div>
            <div className="prefernce-border"></div>
            <div className="prefrence-paddin-add">
                <label >Modality:</label>
                <div className="form-group-pages">
                    {
                        options.map(val => {
                            return (
                                <div key={val.value}>
                                    <div className="chec-margin">
                                        <div class="form-check form-check-inline form-check-lable">
                                            <input class="form-check-input ari-checbox" type="checkbox" id={val.label} value={val.value} checked={preference.preference_modalities && preference.preference_modalities.includes(val.value)} name="preference_modalities" onChange={handleModalitys} />
                                            <label class="form-check-label" for={val.label}>{val.label}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="form-group">
                <label >Sub Speciality::</label>
                    <Select options={referencedomainvalue.map((res) => (
                        { "value": res.id, "label": res.code }
                    ))} value={referencedomainvalue.map(res => ({ "value": res.id, "label": res.code })).find(val=>preference.preference_speciality&&preference.preference_speciality.includes(val.value))} placeholder="Sub Speciality" onChange={(selectedOption) => handleSelectChange(selectedOption, "preference_speciality")} />
                </div>
            </div>
            <div className="prefrence-paddin">
                <h5 className="dashboard-subtitle">non - Preferences</h5>
            </div>
            <div className="prefernce-border"></div>
            <div className="prefrence-paddin-add">
                <label >Modality:</label>
                <div className="form-group-pages">
                    {
                        options.map(val => {
                            return (
                                <div key={val.value}>
                                    <div className="chec-margin">
                                        <div class="form-check form-check-inline form-check-lable">
                                            <input class="form-check-input ari-checbox" type="checkbox" id={val.label} value={val.value} checked={preference.nopreferences_modalities && preference.nopreferences_modalities.includes(val.value)} placeholder="Sub Speciality" name="nopreferences_modalities" onChange={handleModalitys} />
                                            <label class="form-check-label" for={val.label}>{val.label}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="form-group">
                <label >Sub Speciality::</label>
                    <Select options={referencedomainvalue.map((res) => (
                        { "value": res.id, "label": res.code }
                    ))}  value={referencedomainvalue.map(res => ({ "value": res.id, "label": res.code })).find(val=>preference.nopreferences_speciality&&preference.nopreferences_speciality.includes(val.value))} placeholder="Sub Speciality" onChange={(selectedOption) => handleSelectChange(selectedOption, "nopreferences_speciality")} />
                </div>
                <div className="">
                <button className="btn btn-primary" onClick={formSubmit}>Save</button>
            </div>
            </div>
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} />
            }
        </div>

    )
}
export { Preference }