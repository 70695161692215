import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const SubModuleEdit = () => {
    const MastersModal=Masters.SubModule
    const[popupType,setpopupType]=useState("")
    const[Error,SetError]=useState("")
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/submodule/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            if(error.response.data.code[0]==="This field must be unique."){
                SetError("Code Already Exists.")
 
               }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/submodule/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Sub Module {id}</h5>
                    <NavLink to="/dashboard/masters/submodule" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {
                    popupType!=""&&
                    <PopupModal Navigation="/dashboard/masters/submodule" popupType={popupType} />
                }
            </DashboardLayout>
        </>
    )

}
export {SubModuleEdit}