import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal,EditPopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import RolePermission from "../Components/DashboardLayout/RolePermissionData";
import Select from 'react-select';
const RoleEdit = () => {
    const MastersModal = Masters.Role
    const [roledata, setRoleData] = useState({ permission: false })
    const[popupType,setpopupType]=useState("")
    const[EditPopupType,setEditpopupType]=useState("")
    const[RolePermission,setRolePermission]=useState([]) //post
    const[RolePermissionUpdate,setRolePermissionUpdate]=useState([]) //update
    const[DefaultRole,SetDefaultrole]=useState({}) //get existing permissions
    const [search, SetSearch] = useState({
        search: ""
    })
    const formSubmit = (Data) => {
        axiosConfig.put(`/account/roleview/${id}/`, Data).then(res => {
            console.log(res)
            setEditpopupType("success")
        }).catch(error => {
            console.log(error)
            setEditpopupType("error")
        })
    }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    console.log(Data.role_name)
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/account/roleview/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const roleModalPemrission=async()=>{
        try {
            const responce = await axiosConfig.get(`/account/roleModulepermission/?role=${id}`)
            SetExistingPermissions(responce.data.results)
             let data=[]
              responce.data.results.forEach(res=>{
                console.log(res)
                DefaultRole[res.modulescreen]={read:res.read,edit:res.edit,write:res.write,delete:res.delete}
                   const existingIndex=data.findIndex(find=>find.role===id)
                   if(existingIndex===-1){
                       data.push({role:id,"module_permission":[{
                          "modulescreen": res.modulescreen,
                           read:res.read,
                           write:res.write,
                           edit:res.edit,
                           delete:res.delete
                       }]}) 
                   }
                   else{
                       data[existingIndex]['module_permission'].push({
                           "modulescreen": res.modulescreen,
                           read:res.read,
                           write:res.write,
                           edit:res.edit,
                           delete:res.delete
                       })
                   }
             })
             Rolepermission(...data)
            }
        catch (error) {
            console.log(error)
        }
    }
    const [pmsdata, setpmsdata] = useState([]) //masters
    const[Datafilter,SetDatafilter]=useState([])
    const[ExistingPermissions,SetExistingPermissions]=useState([]) //get existing permissions
    const Rolepermission = async (updatedata) => {
        try {
            const responce = await axiosConfig.get(`/masters/module/`)
            setpmsdata(responce.data.results)
            SetDatafilter(responce.data.results)
             let data=[]
             responce.data.results.forEach(res=>{
                 res.sub_module.forEach(submodule=>{
                    submodule.module_screen.forEach(module=>{
                     const existingIndex=data.findIndex(find=>find.role===id)
                     if(existingIndex===-1){
                        
                         data.push({role:id,"module_permission":[{
                            "modulescreen": module.id,
                             read:"False",
                             write:"False",
                             edit:"False",
                             delete:"False"
                         }]}) 
                     }
                     else{
                         data[existingIndex]['module_permission'].push({
                             "modulescreen": module.id,
                             read:"False",
                             write:"False",
                             edit:"False",
                             delete:"False"
                         })
                     }
                    })
                 })
                  setRolePermission(...data)
             })
        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
   const responce= Datafilter.filter(res=>res.name&&res.name.toLowerCase().includes(value.toLowerCase()))
   setpmsdata(responce)
    }
    useEffect(() => {
        GetData();
        Rolepermission();
        roleModalPemrission();
    }, [])
   const[rolepmtData,setRolePmtdata]=useState([])
    const HandleChange = (e,headid,titleid,title) => {
        const { name, value } = e.target
         console.log(titleid,name,value)
      const Index=rolepmtData.findIndex(find=>find.role===id)
      console.log(Index)
      if(Index===-1){
        setRolePmtdata([...rolepmtData,{role:id,module_permission:[{modulescreen:titleid,[name]:value}]}])
      }
      else{
        const Updatata=[...rolepmtData]
        Updatata.forEach(res=>{
           const Index2=res.module_permission.findIndex(find=>find.modulescreen===titleid)
           console.log(Index2)
           if(Index2===-1){
            res['module_permission'].push({
                modulescreen:titleid,
                [name]:value
            })
           }
           else{
            res.module_permission[Index2][name]=value
            setRolePmtdata(Updatata)
           }
        })
      }
    //       const Updatata=RolePermission
    //       Updatata.module_permission[Index][name]=value
    //       console.log(Updatata)
    //        setRolePermission(Updatata)
    }
    console.log(rolepmtData)
    const formSubmitRolePermission = () => {
        axiosConfig.post(`/account/roleModulepermission/${id}/role_permission/`, ...rolepmtData).then(res => {
            console.log(res)
            setpopupType("success")
            roleModalPemrission();
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Role {id}</h5>
                    <NavLink to="/dashboard/masters/role" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="accordion-container">
                    <div className="accordion" id="accordionClient">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <h5 className="dashboard-subtitle">View/Edit Role</h5>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <h5 className="dashboard-subtitle">Role Permissions</h5>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse  show" aria-labelledby="headingTwo" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <div className="d-flex justify-content-between">
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />
                                        <div>
                                            <button type="submit" className="btn btn-primary" onClick={formSubmitRolePermission}>Save</button>
                                        </div>
                                    </div>
                                    <div className="row role-main-heading">
                                        <div className="col-4">
                                            <p className="role-heading">Permissions</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">Read</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">Write</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">update</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="Crud-role">Delete</p>
                                        </div>
                                    </div>
                                    {
                                        pmsdata.map((head, id) => {
                                            console.log(head)
                                            return (
                                                <div key={id}>
                                                    <h5 className="dashboard-subtitle">{head.name}</h5>
                                                    {
                                                        head.sub_module.map(res => {
                                                            return (
                                                                <div key={res.id}>
                                                                    <div className="role-menu-header">
                                                                        <h5 className="dashboard-subtitle">{res.name}</h5>
                                                                    </div>
                                                                    <div className="role-actions">
                                                                            {
                                                                                res.module_screen.map((title, i) => {
                                                                                    return (
                                                                                        <div key={i}>
                                                                                             <div className="row">
                                                                                            <div className="col-4">
                                                                                                <p className="role-heading">{title.name}</p>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                             <div class="form-check form-check-inline">
                                                                                                   
                                                                                                  {/* {ExistingPermissions.map(itm =>{
                                                                                                    return(
                                                                                                       <div>
                                                                                                        <input className="form-check-input role-check" name="read" defaultChecked={itm.module_permission.modulescreen.id===title.id&&itm.module_permission.read} type="checkbox" id="inlineCheckbox1" value={itm.read}
                                                                                                        onChange={(e) => HandleChange(e,res.id,title.id,title.name)} />
                                                                                                        </div>
                                                                                                    )

                                                                                                })}    */}
                                                                                                      <input className="form-check-input role-check" name="read" defaultChecked={DefaultRole[title.id]&&DefaultRole[title.id].read} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e,res.id,title.id,title.name)} />    
                                                                                                </div>
                                                                                            </div>
                                                                                               <div className="col-2">
                                                                                                <div class="form-check form-check-inline">
                                                                                                    <input className="form-check-input role-check" name="write" defaultChecked={DefaultRole[title.id]&&DefaultRole[title.id].write} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e,res.id,title.id,title.name)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                                <div class="form-check form-check-inline">
                                                                                                    <input className="form-check-input role-check" name="edit" defaultChecked={DefaultRole[title.id]&&DefaultRole[title.id].edit} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e,res.id,title.id,title.name)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-2">
                                                                                                <div class="form-check form-check-inline">
                                                                                                    <input className="form-check-input role-check" name="delete"  defaultChecked={DefaultRole[title.id]&&DefaultRole[title.id].delete} type="checkbox" id="inlineCheckbox1" value="True"
                                                                                                        onChange={(e) => HandleChange(e,res.id,title.id,title.name)} />
                                                                                                </div>
                                                                                            </div>  
                                                                                        </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" Message="Role Permission Added SuccessFully"/>
                }
                 {EditPopupType != "" &&
                    <EditPopupModal EditPopupType={EditPopupType} setEditpopupType={setEditpopupType} Navigation="" Message="Role Updated SuccessFully"/>
                }
            </DashboardLayout>
        </>
    )

}
export { RoleEdit }