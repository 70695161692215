import React, { useEffect, useState, useRef } from "react";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import { DeletePopupModal } from "../Components";
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import { DashboardLayout, Pagination } from "../Components";
import { NavLink, json, useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
import { Button, Dropdown } from 'react-bootstrap';
import { GrNewWindow } from "react-icons/gr"
import { HiOutlineUsers } from "react-icons/hi"
import { BiSpreadsheet } from "react-icons/bi"
import { ImFilesEmpty } from "react-icons/im"
import { VscFiles } from "react-icons/vsc"
import { RiUserFollowLine } from "react-icons/ri"
import { GrGallery } from "react-icons/gr"
import {MdOutlineAddTask} from "react-icons/md"
import { TiAttachment } from "react-icons/ti"
import { AiOutlineUserAdd, AiOutlineMessage, AiOutlineFileText } from "react-icons/ai"
const Worklists = ({ Code }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tableRef = useRef(null);
    let month = searchParams.get("month")
    console.log(month)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [deletePopupType, setDeletepopupType] = useState("")
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [tableId, setTableId] = useState()
    const [hoveId, setHoverId] = useState()
    const [details, setDetails] = useState({
        Total_Clients: 0,
        Total_Cases: 0,
        Total_Price: 0.0,
    });
    const [ActiveClass, setActiveClass] = useState("New")
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [search, SetSearch] = useState({
        search: "",
        month: ""
    })
    const [select, Setselct] = useState({
        month: ""
    })

    const [Datafilter, SetDatafilter] = useState([])
    const [page, NextPage] = useState(1)
    console.log(page)
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
    };

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const GetData = async () => {
        setLoading(true)
        try {

            const responce = await axiosConfig.get(`/cases/case/?page=${currentPage}&page_size=${RowPerPage}&status=${ActiveClass}`);
            console.log(responce)
            SetData(responce.data.results);
            setLoading(false);
            setTotalRows(responce.data.count);
        }
        catch (error) {
            console.log(error)
        }
    }
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData();
    }, [currentPage, RowPerPage, currentColumn, currentDirection, ActiveClass]);
    const HandleNextPage = () => {
        NextPage(page + 1)
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        const response = Datafilter.filter(data => data["Inst.Group"] && data["Inst.Group"].toLowerCase().includes(value.toLowerCase()) || data.client_code && data.client_code.toLowerCase().includes(value.toLowerCase()) || data.client_name && data.client_name.toLowerCase().includes(value.toLowerCase()) || data.total_amount && data.total_amount.toString().includes(value) || data.no_of_cases && data.no_of_cases.toString().includes(value) || data.client_id && data.client_id.toString().includes(value))
        console.log(response)
        SetData(response)
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        month = value
        setSearchParams({ 'month': value });
        GetData();
    }
    const HandleMouse = (row) => {
        setTableId(row.patient_id)
        setHoverId(row.patient_id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`; 
        setDataTableHeight(newHeight);
    };
    const TimeChange=(time)=>{
        if(time){
            const timeParts = time.split('T')[1].split(':');
            let hours = parseInt(timeParts[0]);
            const minutes = timeParts[1];
            let ampm = 'am';
        
            if (hours >= 12) {
              ampm = 'pm';
              if (hours > 12) {
                hours -= 12;
              }
            }    
          return `${hours}:${minutes}${ampm}`;
        }
        else{
            return null
        }
    }
    const handleRowClick=(row)=>{
        console.log(row)
          navigate(`/dashboard/worklistExpand/${row.id}`)
    }
    const columns = [
        // <th>ACTIONS</th>
        {
            name: "Instutution Name",
            selector: "instutution_name",
            cell: (row) => <div className="table-ins-name ">
                {row.instutution_name}
            </div>,
            sortable: true,
            maxWidth: "250px",
            left: true

        },
        {
            name: "Patient",
            selector: "patient_id",
            cell: (row) => <>
                <div className="align-ments">
                        <div>{row.patient_id}</div>
                        <div className="images-table"><span className="icon-image"><GrGallery /></span>Total images received |<span className="images-count">200</span></div>
                </div>
            </>,
            sortable: true,
            width: "250px",
            maxWidth: "250px",
            left: true

        },
        {
            name: "Patient Name",
            selector: "row.priority_name",
            cell: (row) => <>
                <div className="align-ments">
                    <div>{row.patient_name} {row.age}Y/ {row.modality_name} {row.study_description}</div>
                    <div className="images-icon-attch">
                        <div className="images-table-attach"><span className="icon-image"><TiAttachment /></span>Attachments</div>
                        <div className="images-table-attach"><span className="icon-image"><AiOutlineFileText /></span>History</div>
                    </div>
                </div>
            </>,
            sortable: true,
            left: true

        },
         {
             name: "study description",
             selector: "priority_name",
             cell: (row) =><>
             <div className="stat-worklist">
            <span className={row.priority_name==="STAT"?"align-ments-stat":"align-ments-gen"}>{row.priority_name}</span>
             {tableId===row.patient_id&&
                <><span className="mx-4">Set As</span>
             <span><MdOutlineAddTask/></span>
             <span>{TimeChange(row.study_completed_date_time)}</span>
             </>}
             </div>
             </>,
              width: "150px",
              maxWidth: "150px",
         },
        // {
        //     name: "study description",
        //     selector: "study_description",
        //     cell: (row) => `${row.study_description}`,
        //     sortable: true,
        //     maxWidth: "250px",
        //     hide: 'sm',

        // },
        // {
        //     name: "priority name",
        //     selector: "priority_name",
        //     cell: (row) => `${row.priority_name}`,
        //     sortable: true,
        //     maxWidth: "250px",
        //     hide: 'sm',

        // },
        {
            name: "Actions",
            selector: "Actions",
            cell: row => {
                const shouldShowActions=tableId===row.patient_id
                return (
                    <>
                        {shouldShowActions ? (
                            <div className="row">
                                {actionPermisson && actionPermisson.read && (
                                    <>                                    
                                    <div className=" col-4">
                                       <RiUserFollowLine className="dashboard-tabe-data-actions"/>
                                    </div>
                                    <div className=" col-4">
                                        <AiOutlineUserAdd className="dashboard-tabe-data-actions"/>
                                        </div>
                                        <div className=" col-4">
                                         <AiOutlineMessage className="dashboard-tabe-data-actions"/>
                                        </div>
                                        </>
                                )}
                            </div>
                        ):<div> <span className="study-description">Set As</span>
                        <span className="mx-4"><MdOutlineAddTask/></span>
                        <span>{TimeChange(row.study_completed_date_time)}</span>
                        </div>}
                    </>
                );

            },
        }
    ]
    const HandleAcitvetab = (status) => {
        setActiveClass(status)
    }
    return (
        <>
            <DashboardLayout>
                {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Final Bills</h5>
                    <input type="month" name="month" className="form-control month-selector" value={month} onChange={HandleSelect} />
                </div> */}
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                    </div>
                    <div className="table-responsive table-header-main-none">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page, totalRows) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer
                            fixedHeader
                            customStyles={tableCustomStyles}
                            fixedHeaderScrollHeight={dataTableHeight}
                            subHeader
                            selectableRows
                              onSelectedRowsChange={({ selectedRows }) =>
                              console.log(selectedRows)
                            }
                            selectableRowsHighlight
                            highlightOnHover
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            onRowClicked={(row) => handleRowClick(row)}
                            subHeaderComponent={
                                <div className="serach-class">
                                    <div className={ActiveClass === "New" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("New")}><span><GrNewWindow /></span>New</div>
                                    <div className=""></div>
                                    <div className={ActiveClass === "Assigned" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("Assigned")}><span><HiOutlineUsers /></span>Assigned</div>
                                    <div className={ActiveClass === "Read_draft" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("Read_draft")}><span><BiSpreadsheet /></span>Read Draft</div>
                                    <div className={ActiveClass === "Final_draft" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("Final_draft")}><span><ImFilesEmpty /></span>Draft Final</div>
                                    <div className={ActiveClass === "Report_final" ? "active-worklist-subtitle" : "worklist-subtitle"} onClick={() => HandleAcitvetab("Report_final")}><span><VscFiles /></span>Report Final</div>
                                </div>
                            } />
                    </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { Worklists }