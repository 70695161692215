 const tableCustomStyles = {
    rows: {
      style: {
        // justifyContent:"center"
                   },
    },
    cells: {
        style: {
             justifyContent:"center"
                       },
    },
    headCells: {
      style: {
         justifyContent:"center",
        color:"rgb(33,37,41)",
        fontWeight:700,
        lineHeight:"24px",
        textTransform:"Uppercase",
      },
    },
    }
    export default tableCustomStyles