import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { BasicInformation,ProfessionalDetails,Certificates,Signature,Preference,ResetPassword } from "../Pages"

const ExpandedComponent = ({data,HandleCount,count,setCount}) => {
    return (
        <div className="nav-custom-table">
            <ul className="nav nav-tabs nav-tabs-exp" id="myTab" role="tablist">
               <li className="nav-item" role="presentation" onClick={()=>HandleCount(1)}>
                    <button className={count===1?"nav-link active":"nav-link"} id="Personal / Basic Information" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Personal / Basic Information</button>
                </li>
                <li className="nav-item" role="presentation" onClick={()=>HandleCount(2)}>
                    <button className={count===2?"nav-link active":"nav-link"} id="Professional Details" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Professional Details</button>
                </li>
                <li className="nav-item" role="presentation" onClick={()=>HandleCount(3)}>
                    <button className={count===3?"nav-link active":"nav-link"} id="Certificates" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Certificates</button>
                </li>
                <li className="nav-item" role="presentation" onClick={()=>HandleCount(4)}>
                    <button className={count===4?"nav-link active":"nav-link"} id="Signature" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Signature</button>
                </li>
                 <li className="nav-item" role="presentation" onClick={()=>HandleCount(5)}>
                    <button className={count===5?"nav-link active":"nav-link"} id="Preference" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Preference</button>
                </li>
                <li className="nav-item" role="presentation" onClick={()=>HandleCount(6)}>
                    <button className={count===6?"nav-link active":"nav-link"} id="Reset Password" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Reset Password</button>
                </li>
            </ul>
            <div className="tab-content-details" id="myTabContent">
               <>
              {count===1&&<BasicInformation data={data} HandleCount={HandleCount} setCount={setCount} count={count} />}
              {count===2&&<ProfessionalDetails  data={data} HandleCount={HandleCount} setCount={setCount} count={count}/>}
              {count===3&&<Certificates  data={data} HandleCount={HandleCount} setCount={setCount} count={count}/>}
              {count===4&&<Signature  data={data} HandleCount={HandleCount} setCount={setCount} count={count}/>}
              {count===5&&<Preference  data={data} HandleCount={HandleCount} setCount={setCount} count={count} />}
              {count===6&&<ResetPassword  data={data} HandleCount={HandleCount} setCount={setCount} count={count}/>}
               </>
            </div>
        </div>
    )
}
export { ExpandedComponent }