import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
const RoleModulePermisionList=()=>{
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    // const GetData = async () => {
    //     try {
    //         const responce = await axiosConfig.get("/account/roleModulepermission/")
    //         SetData(responce.data)
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }

    // }
     useEffect(() => {
        // GetData();
    }, [])

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Role",
            selector: row => row.role,
            sortable: true
        },
        {
            name: "module Permissions",
            selector: row => row.module_permission,
            sortable: true
        },
         {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async() => {
                    return navigate(`/dashboard/masters/rolemodulepermission/${row.id}/edit`);
                }
                const HandleDelete = async() => {
                    await axiosConfig.delete(`/account/roleModulepermission/${row.id}/`).then(()=>{
                        // GetData()
                    }).catch((error)=> {
                        console.log(error)
                    })
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={HandleEdit}/>
                            </div>
                        </div>
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                {/* <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Role Module Permissions</h5>
                    <NavLink to="/dashboard/masters/rolemodulepermission/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                
                <DataTable columns={columns} data={Data} pagination fixedHeader /> */}
                <div className="mt-5">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page is work in progress</p>
                </div>
                
            </DashboardLayout>
        </>
    )
}
export { RoleModulePermisionList }