import React, { useState, useEffect } from "react";
import { NotFound } from "../../Pages";
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
const FormParser = ({ modelObject, formData, formSubmit, error,  button, buttonTitle,}) => {
    const [Data, SetData] = useState({})
    const [loading, setLoading] = useState(true);
    const [dynamicOptions, SetDynamicOptions] = useState({})
    const [DataFilter, SetDataFilter] = useState([])
    const [Option, setOption] = useState({});
    const [Error, SetError] = useState("")
    const [Nextbutton, setNextbutton] = useState("")
    const GetData = async () => {
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicdropdown") {
                    const response = await axiosConfig.get(field_obj.options_url);
                    const count = Math.ceil(response.data.count / 20);
                    SetDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [field_obj.fieldName]: response.data.results
                    }));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const GetMultiData = async () => {
        let data = []
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicdropdown") {
                    const response = await axiosConfig.get(field_obj.options_url);
                    const count = Math.ceil(response.data.count / 20);
                    SetDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [field_obj.fieldName]: response.data.results
                    }));
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    // const GetDynamicData = (id) => {
    //     console.log(id)
    //     let data=[]
    //     field_objects.forEach((field_obj, ind) => {
    //         if (field_obj.fieldType === "dynamicSelectdropdown") {
    //             axiosConfig.get(`${field_obj.options_url}${id}/`).then(response => {
    //                 SetSelectedOptions({ ...SelectedOptions, [field_obj.fieldName]: response.data })
    //                 SelectedOptions[field_obj.fieldName] = response.data
    //                 console.log(response.data)
    //             }).catch((error) => {
    //                 console.log(error)
    //             })
    //         }
    //     })
    // }
    const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([])
    const SelectOptions = (id) => {
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                console.log(id, `${item.options_url}${id}`)
                let url = `${item.options_url}${id}`
                axiosConfig.get(url).then(res => {
                    console.log(res.data.results)
                    SetSelectDynamicOptions(res.data.results)
                }).catch(error => {
                    console.log(error)
                })
            }
        })
    }
    const editDataRender = async (formData) => {
        field_objects.forEach(async (field_obj, idx) => {
            if (formData) {
                Data[field_obj.fieldName] = formData[field_obj.fieldName]
                SetData(formData)
                if (field_obj.fieldType === "dynamicSelectdropdown") {
                    if (formData[field_obj.renderField]) {
                        let url = `${field_obj.options_url}${formData[field_obj.renderField]}`
                        console.log(url)
                        await axiosConfig.get(url).then(res => {
                            SetSelectDynamicOptions(res.data.results)
                            const find = res.data.results.find(find => find.id === formData[field_obj.fieldName])
                            setOption({ value: find.id, label: find[field_obj.displayField] })
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
                if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
                    let data = []
                    formData && formData[field_obj.fieldName] && formData[field_obj.fieldName].forEach(value => {
                        const findData = dynamicOptions[field_obj.fieldName].find(find => find.id === value)
                        data.push(findData)
                    })
                    setSelectedOptions(data)
                }
            }
        })
        setTimeout(function () {
            setLoading(false);
        }.bind(this), 500)
    }
    const [selectedOptions, setSelectedOptions] = useState([])
    useEffect(() => {
        GetData();
        GetMultiData()
        editDataRender(formData)
    }, [formData])
    const [FilterData, SetFilterdata] = useState([])
    const [Name, SetName] = useState("")
    const HandleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        SetData({ ...Data, [name]: value })
        validateField(name, value)
        SetName(value)
    }
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption.value, name)
        SetData({ ...Data, [name]: selectedOption.value });
        SelectOptions(selectedOption.value)
    };
    const handleMultiSelectChange = (selectedOption, valu, name) => {
        let data = []
        selectedOption.forEach(res => {
            data.push(res.value)
        })
        SetData({ ...Data, [name]: data });
    };
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const HandleMultiPulFiles = (e) => {
        e.preventDefault()
        const file = e.target.files;
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const OnSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        for (let key in Data) {
            formData.append(key, Data[key]);
            formData[key] = Data[key];
          }
            formSubmit(formData)
    }
    console.log(Data)
    const validateField = (name, value) => {
        const regex = {
            country_name: /^[a-zA-Z]+$/,
            state_name: /^[a-zA-Z]+$/,
            city_name: /^[a-zA-Z]+$/,
            pincode: /^[0-9]+$/,
            email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            cpt_code: /^[0-9]+$/,
        };
        const isInputValid = regex[name] ? regex[name].test(value) : true;
        const fields = field_objects.find(fields => fields.fieldName === name)
        console.log(isInputValid)
        SetError(isInputValid ? '' : `Enter a valid ${fields.label}`)

    }
    let field_objects = []
    modelObject.orderBy.forEach((order_obj, idx) => {
        modelObject.fields.forEach((field_obj, index) => {
            if (field_obj.fieldName === order_obj) {
                let flagAddClass = true
                modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
                    if (classObj[field_obj.fieldName]) {
                        field_obj['groupClass'] = classObj[field_obj.fieldName]
                        flagAddClass = false
                    }
                })
                if (modelObject.layoutClass && flagAddClass) {
                    field_obj['groupClass'] = modelObject.layoutClass
                }
                field_objects.push(field_obj)
            }
        })
    })
    const handleOptionClick = () => {
        setNextbutton("Back")
    }

    return (
        <>
            <div className="container-form">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('./images/loader2.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <form onSubmit={OnSubmit}  encType="multipart/form-data">
                        <div className="Loginerror">
                            <p>{error}</p>
                        </div>
                        {Error && <div className="Loginerror">
                            <p>{Error}</p>
                        </div>}
                        <div className="row">
                            {field_objects &&
                                field_objects.map((fieldItem, i) => {
                                    return (
                                        <div key={fieldItem.fieldName} className={fieldItem.groupClass}>
                                            {fieldItem.fieldType === "string" &&
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}:</label>
                                                    <input type="text" className="form-control" id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                    {/* <div className="Loginerror">
                                                    <p>{error}</p>
                                                </div> */}
                                                </div>}
                                            {fieldItem.fieldType === "email" &&
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}:</label>
                                                    <input type="email" className="form-control" id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                    {/* <div className="Loginerror">
                                                    <p>{error}</p>
                                                </div> */}
                                                </div>}
                                            {fieldItem.fieldType === "url" &&
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}:</label>
                                                    <input type="url" className="form-control" id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                    {/* <div className="Loginerror">
                                                    <p>{error}</p>
                                                </div> */}
                                                </div>}
                                            {fieldItem.fieldType === "boolean" &&
                                                <div className="form-group form-check">
                                                    <label className="form-check-label">
                                                        <input className="form-check-input" type="checkbox" name={fieldItem.fieldName} value="true" onChange={HandleChange} required={fieldItem.required} /> {fieldItem.label}
                                                    </label>
                                                </div>
                                            }
                                            {fieldItem.fieldType === "file" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="file" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleFiles} required={fieldItem.required} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "date" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="date" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "time" &&
                                                <div className="form-group">

                                                    <label >{fieldItem.label}:</label>
                                                    <input type="time" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "integer" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="number" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "password" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="password" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "radio" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <br />

                                                    {fieldItem.options &&
                                                        fieldItem.options.map((optionItem, index) => {
                                                            return (
                                                                <div className="form-check-inline" key={index}>
                                                                    <label className="form-check-label">
                                                                        <input type="radio" className="form-check-input" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required} />{optionItem}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            }
                                            {fieldItem.fieldType === "textarea" &&
                                                <div className="form-group">
                                                    <label>{fieldItem.label}:</label>
                                                    <textarea className="form-control" rows="2" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} required={fieldItem.required} defaultValue={Data[fieldItem.fieldName]}></textarea>
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required} >
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            fieldItem.options.map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem} key={i}>{optionItem}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    {/* <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required}>
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem.id} key={i} selected={optionItem.id === Data[fieldItem.fieldName]}>{optionItem[fieldItem.displayField]}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>  */}
                                                    <Select options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label} value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} required={fieldItem.required} />
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dynamicSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    {console.log(Data[fieldItem.fieldName])}
                                                    <Select options={SelectDynamicOptions && SelectDynamicOptions.map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label} value={SelectDynamicOptions && SelectDynamicOptions.map((res) => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(find => find.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} required={fieldItem.required} />
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicMultiSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <Select isMulti options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label}
                                                        value={selectedOptions.map(res => ({ value: res.id, label: res[fieldItem.displayField] }))}
                                                        onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, fieldItem.fieldName)} required={fieldItem.required} />
                                                </div>
                                            }

                                            {/* {
                                            fieldItem.fieldType === "DynamicSelectOptions" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}:</label>
                                                <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required}>
                                                    <option value="">SELECT {fieldItem.label}</option>
                                                    {
                                                       SelectDynamicOptions.map((optionItem, i) => {
                                                            return (
                                                                <option value={optionItem.id} key={i} selected={optionItem.id===Data[fieldItem.fieldName]?Data[fieldItem.fieldName]:undefined} >{optionItem[fieldItem.displayField]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        } */}
                                            {fieldItem.fieldType === "mulitipulFiles" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}</label>
                                                <input className="form-control" type="file" id="formFileMultiple" name={fieldItem.fieldName} multiple onChange={HandleMultiPulFiles} required={fieldItem.required} />
                                            </div>
                                            }
                                            {fieldItem.fieldType === "status" && <div key={i} className="form-group" className="form-check form-switch">
                                                <div>
                                                    <label className="form-check-label">STATUS</label>
                                                </div>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} />

                                            </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <button type="submit" className={`btn btn-primary ${button ? button : ""}`}>{buttonTitle ? buttonTitle : "Submit"}</button>                    
                    </form>
                )}
            </div>

        </>
    )
}
export { FormParser }