import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import DataTable from "react-data-table-component";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json"
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import { ExpandedComponent } from "./ExpandedComponents";
import { AiOutlineSearch } from 'react-icons/ai';
import { TbReportAnalytics, TbFileInvoice } from 'react-icons/tb';
import { CgAttachment } from 'react-icons/cg';
import { MdOutlineMessage } from 'react-icons/md';
const RadioLogistLsit = ({ Code }) => {
    const [visible, SetVisable] = useState([])
    const [action, setaction] = useState(0)
    const [action2, setaction2] = useState(0)
    const [action3, setaction3] = useState(0)
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Datafilter, SetDatafilter] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [tableId, setTableId] = useState([])
    const [check, setCheck] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const [count, setCount] = useState(1)
    const [stateID, setStateId] = useState("")
    const masterModal = Masters.Radiologist
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();
    const expandedRowClassName = 'expanded-row';
    const collapsedRowClassName = 'collapsed-row';
    const [Data, SetData] = useState([])
    const [expandedRows, setExpandedRows] = useState([]);
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/account/users/?page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const formSubmit = (Data) => {
        console.log(Data)
        SetError([])
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.post("/account/users/", { ...Data, roles: 32 }).then(res => {
            console.log(res)
            setpopupType("success")
            setModalShow(false)
        }).catch(error => {
            const errorData = error.response.data || {};
            const emailError = errorData.email ? errorData.email[0] : null;
            const mobilenoError = errorData.mobileno ? errorData.mobileno[0] : null;
            const passwordError = errorData.password ? errorData.password[0] : null;
            let errorMessage = null;

            if (emailError) {
                errorMessage = "user with this email address already exists.";
            } else if (mobilenoError) {
                errorMessage = "user with this mobileno already exists.";
            } else if (passwordError) {
                errorMessage = "password field is required.";
            }
            if (errorMessage) {
                SetError(errorMessage);
                console.log(errorMessage)
            }
            setpopupType("error")
        })
    }
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        const newHeight = `${screenHeight - 180}px`;
        setDataTableHeight(newHeight);
    };
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        axiosConfig.get(`/account/users/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleCount = (num) => {
        console.log(num)
        setCount(num)
    }
    const HandleMouse = (row) => {
        setTableId(row.id);
    };

    const HandleRowMouseLeave = (row) => {
        setCount(count)
        setTableId(null);
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Full Name",
            selector: "",
            cell: (row) => `${row.first_name}${row.last_name}`,
            sortable: true,

        },
        {
            name: "Mobile Number",
            selector: "mobileno",
            cell: (row) => `${row.mobileno}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "Email",
            selector: "email",
            cell: (row) => `${row.email}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "Status",
            selector: (row) => {
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/account/users/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                const shouldShowActions = tableId === row.id;
                const ExpandIcon = expandedRows.includes(row.id) ? <BsChevronDown /> : <BsChevronLeft />;

                const toggleRowExpansion = (row) => {
                    if (expandedRows.includes(row.id)) {
                        setExpandedRows(expandedRows.filter((id) => id !== row.id));
                    } else {
                        setExpandedRows([...expandedRows, row.id]);
                    }
                };
                return (
                    <>{
                        shouldShowActions && (

                            <div className="row text-center" >
                                <div className="col-2" onClick={() => toggleRowExpansion(row)}>
                                    {ExpandIcon}
                                </div>
                                <div className="col-2">
                                    <TbReportAnalytics className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                                <div className="col-2">
                                    <TbFileInvoice className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                                <div className="col-2">
                                    <CgAttachment className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                                <div className="col-2">
                                    <MdOutlineMessage className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                        )
                    }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            },
        }
    ]
    const handleRowsPerPageChange = (event) => {
        console.log(event.target.value);
        setCurrentPage(1);
    };
    const HandleModal = () => {
        setModalShow(true)
    }
    const CustomStyles = {
        rows: {
            style: {
                justifyContent: "center",
            },
        },
        cells: {
            style: {
                justifyContent: "center",
            },
        },
        headCells: {
            style: {
                backgroundColor: "#F6FDFF",
                justifyContent: "center",
                color: "rgb(33, 37, 41)",
                fontWeight: 700,
                lineHeight: "24px",
                textTransform: "uppercase",
            },
        },
    };

    return (
        <>
            <DashboardLayout>
                <div className="tablescroll-clientbill">
                        <div className="padd-main">
                        <div className="button-add">
                                        <button className="btn btn-white1" onClick={HandleModal}>Add New</button>
                                    </div>
                                    <div className="table-actions">
                        <div className="table-header-menu-radio">
                            <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                <Dropdown.Toggle variant="">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onClick={handleCheckboxClick}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                    <Dropdown.Item>ARI</Dropdown.Item>
                                    <Dropdown.Item>SPECIALIST OPINION</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <MdOutlineRefresh className="sidebar-icon-table" />
                            <CiMenuKebab className="sidebar-icon-table" />
                        </div>
                        <div>
                            <div>
                                <input type="text" className="form-control" name="search" value={search.search} onChange={HandleSearch} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page, totalRows) => {
                                setCurrentPage(page);
                            }}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer
                            fixedHeader
                            customStyles={CustomStyles}
                            fixedHeaderScrollHeight={dataTableHeight}
                            selectableRows
                            selectableRowsHighlight
                            highlightOnHover
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowMouseLeave}
                            expandableRows
                            expandableRowsComponent={({ data }) => (
                                <div>
                                    <ExpandedComponent count={count} HandleCount={HandleCount} data={data} />
                                </div>
                            )}
                            expandableRowExpanded={(row) => expandedRows.includes(row.id)}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                </div>
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Create Radiologist
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={masterModal} button="btn-block" buttonTitle="Create Radiologist" formSubmit={formSubmit} error={Error} setCount={setCount} count={count} />
                    </Modal.Body>
                </Modal>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
                {popupType != "" &&
                    <PopupModal popupType={popupType} setpopupType={setpopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { RadioLogistLsit }