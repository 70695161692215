import React, { useEffect,useState } from "react"
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/js/bootstrap.min.js';
import {StaffLogin,Dashboard,DashboardLayout} from "./Components"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./Utils/ProtectedRoute"
import Logout from "./Utils/Logout";
import {AddModality, AddNewModality, ReferenceDoaminAdd, 
  ReferenceDoaminEdit, ReferenceDomainValue, ReferenceDoaminList,
  CountryAdd, CountryEdit, CountryList,
  StateAdd, StateEdit, StateList,
  CityAdd, CityEdit, CityList,
  AreaAdd, AreaEdit, AreaList,
  DepartmentAdd, DepartmentEdit, DepartmentList,SubDepartmentList,SubDepartmentEdit,
  SubDepartmentAdd,ServiceList,ServicesAdd,ServicesEdit,ModuleList,ModuleAdd,ModuleEdit,SubModuleAdd,SubModuleEdit,SubModuleList,
  ModuleScreenAdd,ModuleScreenEdit,ModuleScreenList,ScreenFunctionAdd,ScreenFunctionList,ScreenFunctionsEdit,RoleAdd,RoleEdit,RoleList,RoleModulePermisionList,
  RoleModulePermissionAdd,RoleModulePermissionEdit,ClientCreateAdd,ClientCreateEdit,ClientCreateList,
  ClientAdd,ClientEdit,ClientBilledServices,ClientBillDomestic,ClientBillInternational,WorklistExpandComponent,
  //  ClientBillsView,
  ClientAri,NotFound,
  // Invoices,
  ClientFinalBillsView,UploadCases,UploadCasesList, ClientTariff,AssignTariff,ClientTarifList,
  ReferenceDoaminValueAdd,ReferenceDomainValueEdit,ClientTariffEdit,BillHeadAdd,BillHeadEdit,BillHeadList,
  PriorityAdd,PriorityEdit,PriorityList,TariffTypeAdd,TariffTypeEdit,TariffTypeList,TariffCardAdd,TariffCardList,
  TariffCardEdit,TariffCardEditCustomEdit,RadioLigistAdd,RadioLiigistEdit,RadioLogistLsit,UsersAdd,UsersEdit,UsersList,Worklists,ReportsList,AssetsList,FinanceList,UserRoleModalPermissionList, Invoicestwo, ClientBillsViewtwo,ClientFinalBillsViewtwo,
  BasicInformation,Certificates,Signature,ProfessionalDetails,Preference,ResetPassword,ServiceGroup,ServiceGroupList
} from "./Pages"

function App() { 
  return (
    <Router>
      <Routes>
        <Route path='/' element={<StaffLogin />} />
        <Route path="" element={<ProtectedRoute />} >
        <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<DashboardLayout />} />
          <Route path="/dashboard/masters/domains" element={<ReferenceDoaminList Code="ARIMSC1"/>}/>
          <Route path="/dashboard/masters/domains/add" element={<ReferenceDoaminAdd  Code="ARIMSC32"/>}/>
          <Route path="/dashboard/masters/domains/:id/edit" element={<ReferenceDoaminEdit Code="ARIMSC33"/>}/>

          <Route path="/dashboard/masters/domains/value" element={<ReferenceDomainValue Code="ARIMSC2"/>}/>
          <Route path="/dashboard/masters/domains/:id/value/add" element={<ReferenceDoaminValueAdd  Code="ARIMSC34"/>}/>
          <Route path="/dashboard/masters/domains/value/:id/edit" element={<ReferenceDomainValueEdit  Code="ARIMSC35"/>}/>

          <Route path="/dashboard/masters/countries" element={<CountryList Code="ARIMSC5"/>}/>
          <Route path="/dashboard/masters/countries/add" element={<CountryAdd Code="ARIMSC36"/>}/>
          <Route path="/dashboard/masters/countries/:id/edit" element={<CountryEdit Code="ARIMSC37"/>}/>

          <Route path="/dashboard/masters/states" element={<StateList Code="ARIMSC6"/>}/>
          <Route path="/dashboard/masters/states/add" element={<StateAdd Code="ARIMSC38"/>}/>
          <Route path="/dashboard/masters/states/:id/edit" element={<StateEdit Code="ARIMSC39"/>}/>

          <Route path="/dashboard/masters/cities" element={<CityList Code="ARIMSC7"/>}/>
          <Route path="/dashboard/masters/cities/add" element={<CityAdd Code="ARIMSC40"/>}/>
          <Route path="/dashboard/masters/cities/:id/edit" element={<CityEdit Code="ARIMSC41"/>}/>

          <Route path="/dashboard/masters/areas" element={<AreaList Code="ARIMSC8"/>}/>
          <Route path="/dashboard/masters/areas/add" element={<AreaAdd Code="ARIMSC42"/>}/>
          <Route path="/dashboard/masters/areas/:id/edit" element={<AreaEdit Code="ARIMSC43"/>}/>

          <Route path="/dashboard/masters/departments" element={<DepartmentList Code="ARIMSC9"/>}/>
          <Route path="/dashboard/masters/departments/add" element={<DepartmentAdd Code="ARIMSC44"/>}/>
          <Route path="/dashboard/masters/departments/:id/edit" element={<DepartmentEdit Code="ARIMSC45"/>}/>

          <Route path="/dashboard/masters/sub-departments" element={<SubDepartmentList  Code="ARIMSC10"/>}/>
          <Route path="/dashboard/masters/sub-departments/add" element={<SubDepartmentAdd Code="ARIMSC46"/>}/>
          <Route path="/dashboard/masters/sub-departments/:id/edit" element={<SubDepartmentEdit Code="ARIMSC47"/>}/>

          <Route path="/dashboard/masters/services" element={<ServiceList Code="ARIMSC11"/>}/>
          <Route path="/dashboard/masters/services/add" element={<ServicesAdd Code="ARIMSC48"/>} />
          <Route path="/dashboard/masters/services/:id/edit" element={<ServicesEdit Code="ARIMSC49"/>}/>

          <Route path="/dashboard/masters/billhead" element={<BillHeadList Code="ARIMSC12"/>}/>
          <Route path="/dashboard/masters/billhead/add" element={<BillHeadAdd Code="ARIMSC50"/>}/>
          <Route path="/dashboard/masters/billhead/:id/edit" element={<BillHeadEdit Code="ARIMSC51"/>}/>

          <Route path="/dashboard/masters/priority" element={<PriorityList Code="ARIMSC13"/>}/>
          <Route path="/dashboard/masters/priority/add" element={<PriorityAdd Code="ARIMSC52"/>}/>
          <Route path="/dashboard/masters/priority/:id/edit" element={<PriorityEdit Code="ARIMSC53"/>}/>

          <Route path="/dashboard/masters/tarifftype" element={<TariffTypeList Code="ARIMSC14"/>}/>
          <Route path="/dashboard/masters/tarifftype/add" element={<TariffTypeAdd Code="ARIMSC54"/>}/>
          <Route path="/dashboard/masters/tarifftype/:id/edit" element={<TariffTypeEdit  Code="ARIMSC55"/>}/>
            
          <Route path="/dashboard/masters/module" element={<ModuleList Code="ARIMSC16"/>}/>
          <Route path="/dashboard/masters/module/add" element={<ModuleAdd Code="ARIMSC58"/>}/>
          <Route path="/dashboard/masters/module/:id/edit" element={<ModuleEdit Code="ARIMSC59"/>}/>

          <Route path="/dashboard/masters/submodule" element={<SubModuleList Code="ARIMSC17"/>}/>
          <Route path="/dashboard/masters/submodule/add" element={<SubModuleAdd  Code="ARIMSC60"/>}/>
          <Route path="/dashboard/masters/submodule/:id/edit" element={<SubModuleEdit Code="ARIMSC61"/>}/>

          <Route path="/dashboard/masters/modulescreen" element={<ModuleScreenList Code="ARIMSC18"/>}/>
          <Route path="/dashboard/masters/modulescreen/add" element={<ModuleScreenAdd Code="ARIMSC62"/>}/>
          <Route path="/dashboard/masters/modulescreen/:id/edit" element={<ModuleScreenEdit Code="ARIMSC63"/>}/>


          <Route path="/dashboard/masters/screenfunctions" element={<ScreenFunctionList Code="ARIMSC19"/>}/>
          <Route path="/dashboard/masters/screenfunctions/add" element={<ScreenFunctionAdd Code="ARIMSC64"/>}/>
          <Route path="/dashboard/masters/screenfunctions/:id/edit" element={<ScreenFunctionsEdit Code="ARIMSC65"/>}/>

          <Route path="/dashboard/masters/role" element={<RoleList Code="ARIMSC20"/>}/>
          <Route path="/dashboard/masters/role/add" element={<RoleAdd  Code="ARIMSC66"/>}/>
          <Route path="/dashboard/masters/role/:id/edit" element={<RoleEdit Code="ARIMSC67"/>}/>

          <Route path="/dashboard/masters/users" element={<UsersList Code="ARIMSC22"/>}/>
          <Route path="/dashboard/masters/users/add" element={<UsersAdd  Code="ARIMSC70"/>}/>
          <Route path="/dashboard/masters/users/:id/edit" element={<UsersEdit Code="ARIMSC71"/>}/>

          <Route path="/dashboard/masters/rolemodulepermission" element={<RoleModulePermisionList Code="ARIMSC21"/>}/>
          <Route path="/dashboard/masters/rolemodulepermission/add" element={<RoleModulePermissionAdd Code="ARIMSC68"/>}/>
          <Route path="/dashboard/masters/rolemodulepermission/:id/edit" element={<RoleModulePermissionEdit  Code="ARIMSC69"/>}/>

          <Route path="/dashboard/users/clients" element={<ClientCreateList Code="ARIMSC25"/>}/>
          <Route path="/dashboard/users/clients/add" element={<ClientAdd Code="ARIMSC74"/>}/>
          <Route path="/dashboard/users/clients/:id/edit" element={<ClientEdit Code="ARIMSC75"/>}/>
          {/* <Route path="/dashboard/users/clients/add" element={<ClientCreateAdd/>}/> */}
          {/* <Route path="/dashboard/users/clients/:id/edit" element={<ClientCreateEdit/>}/> */}


          <Route path="/dashboard/billing/upload-cases" element={<UploadCases  Code="ARIMSC26"/>}/>
          <Route path="/dashboard/billing/upload-cases-list" element={<UploadCasesList Code="ARIMSC78"/>}/>

          <Route path="/dashboard/billing/client-bills" element={<ClientBilledServices Code="ARIMSC27" />} />
          <Route path="/dashboard/billing/domestic" element={<ClientBillDomestic Code="ARIMSC28" />} />
          <Route path="/dashboard/billing/international" element={<ClientBillInternational Code="ARIMSC29" />} />
          <Route path="/dashboard/billing/ari" element={<ClientAri Code="ARIMSC30" />} />
          {/* <Route path="/dashboard/billing/client-bills/:id/view/:month" element={<ClientBillsView Code="ARIMSC79" />} /> */}
          <Route path="/dashboard/billing/client-bills/:id/view/:month" element={<ClientBillsViewtwo Code="ARIMSC79" />} />

          {/* <Route path="/dashboard/billing/client-final-bills" element={<Invoices  Code="ARIMSC31"/>}/> */}
          <Route path="/dashboard/billing/client-final-bills" element={<Invoicestwo Code="ARIMSC31" />} />
          {/* <Route path="/dashboard/billing/client-final-bills/:id/view/:month" element={<ClientFinalBillsView Code="ARIMSC80"/>} /> */}
          <Route path="/dashboard/billing/client-final-billss/:id/view/:month" element={<ClientFinalBillsViewtwo Code="ARIMSC80"/>} />

          <Route path="/dashboard/masters/tariffcard" element={<TariffCardList  Code="ARIMSC15"/>}/>
          <Route path="/dashboard/masters/tariffcard/add" element={<TariffCardAdd Code="ARIMSC56"/>}/>
          <Route path="/dashboard/masters/tariffcard/:id/edit" element={<TariffCardEdit Code="ARIMSC57"/>} />
          <Route path="/dashboard/masters/tariffcard_edit/:id/edit" element={<TariffCardEditCustomEdit Code="ARIMSC57" />} />

          <Route path="/dashboard/radiologist" element={<RadioLogistLsit  Code="ARIMSC24"/>}/>
          <Route path="/dashboard/radiologist/add" element={<RadioLigistAdd Code="ARIMSC72"/>}/>
          <Route path="/dashboard/radiologist/:id/edit" element={<RadioLiigistEdit Code="ARIMSC73"/>}/>
          
          <Route path="/dashboard/Work-list/my-work-list" element={<Worklists Code="ARIMSC81"/>}/>
          <Route path="/dashboard/finance/finance" element={<FinanceList/>}/>
          <Route path="/dashboard/reports/reports" element={<ReportsList/>}/>
          <Route path="/dashboard/assets/assets" element={<AssetsList/>}/>
          <Route path="/dashboard/masters/user-module-permission" element={<UserRoleModalPermissionList  Code="ARIMSC23"/>}/>

          <Route path="/dashboard/clients/:id/tariff" element={<ClientTarifList Code="ARIMSC76"/>}/> 
          <Route path="/dashboard/clients/:id/assign-tariff" element={<AssignTariff Code="ARIMSC77"/>}/>

          <Route path="/dashboard/basicinformation" element={<BasicInformation/>}/>
          <Route path="/dashboard/professionaldetails" element={<ProfessionalDetails/>}/>
          <Route path="/dashboard/certificates" element={<Certificates/>}/>
          <Route path="/dashboard/signature" element={<Signature/>}/>
          <Route path="/dashboard/preference" element={<Preference/>}/>
          <Route path="/dashboard/resetpassword" element={<ResetPassword/>}/>
          <Route path="/dashboard/servicegroup" element={<ServiceGroup Code="ARIMSC84"/>}/>
          <Route path="/dashboard/servicegroup/:id" element={<ServiceGroup Code="ARIMSC84"/>}/>
          <Route path="/dashboard/servicegrouplist" element={<ServiceGroupList Code="ARIMSC85"/>}/>
          <Route path="/dashboard/worklistExpand/:id" element={<WorklistExpandComponent/>}/>
          {/* <Route path="/dashboard/clients/:id/assign-tariffedit" element={<ClientTariffEdit/>}/> */}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
