import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const ModuleScreenAdd = () => {
    const MastersModal=Masters.ModuleScreen
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/modulescreen/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error.response.data.code[0])
            if(error.response.data.code[0]==="This field must be unique."){
                SetError("Code Already Exists")
            }
            setpopupType("error")
        })
      }
      console.log(Error)
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Module Screen</h5>
                    <NavLink to="/dashboard/masters/modulescreen" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {
                    popupType!=""&&
                    <PopupModal Navigation="/dashboard/masters/modulescreen" popupType={popupType} />
                }
            </DashboardLayout>
        </>
    )

}
export {ModuleScreenAdd}