import React, { useEffect, useState,useRef } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import DataTable from "react-data-table-component";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import {MdOutlineRefresh} from "react-icons/md"
import {CiMenuKebab} from "react-icons/ci"
const ServiceGroupList = ({ Code }) => {
    const [visible, SetVisable] = useState([])
    const [action, setaction] = useState(0)
    const [action2, setaction2] = useState(0)
    const [action3, setaction3] = useState(0)
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Datafilter, SetDatafilter] = useState([])
    const [actionPermisson, SetActionPermission] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [tableId, setTableId] = useState()
    const[check,setCheck]=useState(false)
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();

    const [Data, SetData] = useState([])

    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/masters/servicegroups/?page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            console.log(response.data)
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    console.log(Data)
    const [dataTableHeight, setDataTableHeight] = useState('500px');
    const calculateDataTableHeight = () => {
        const screenHeight = window.innerHeight;
        console.log(screenHeight)
        const newHeight = `${screenHeight - 180}px`; 
        setDataTableHeight(newHeight);
    };
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
    console.log(actionPermisson.delete)
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/masters/servicegroups/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleMouse = (row) => {
        setTableId(row.id)
    }
    const HandleRowmouseLeave=()=>{
        setTableId()
    }
    function extractDate(dateTimeString) {
        const dateOnly = dateTimeString.split('T')[0];
        return dateOnly;
      }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Group Name",
            selector: "group_name",
            cell: (row) => `${row.group_name}`,
            sortable: true,

        },
        {
            name: "Created Date",
            selector: "cdate",
            cell: (row) => extractDate(row.cdate),
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "serice Amount INR",
            selector: "serice_amount_INR",
            cell: (row) => `${row.serice_amount_INR}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "Serice Amount US",
            selector: "serice_amount_US",
            cell: (row) => `${row.serice_amount_US}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "ACTIONS",
            selector: (row) => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/servicegroup/${row.id}`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/servicegroups/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                const HandleView = async (id) => {
                    return navigate(`/dashboard/servicegroup/${row.id}`);
                }
                const shouldShowActions = tableId === row.id;
                return (
                    <>{
                        shouldShowActions &&(
                            <div className="row text-center" >
                            {actionPermisson && actionPermisson.read && <div className="col-4">
                                <AiOutlineEye className="dashboard-tabe-data-actions" onClick={HandleView} />
                            </div>}
                            {actionPermisson && actionPermisson.edit && <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                            </div>}
                            {actionPermisson && actionPermisson.delete && <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div>}
                        </div>
                        ) 
                    }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
    
            },
        }
    ]
    const handleRowsPerPageChange = (event) => {
        console.log(event.target.value);
        setCurrentPage(1);
    };
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Countries</h5>
                    {actionPermisson && actionPermisson.read && <NavLink to="/dashboard/servicegroup" ><button className="btn btn-white">Add New</button></NavLink>}
                </div>
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>ARI</Dropdown.Item>
                                <Dropdown.Item>SPECIALIST OPINION</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                         {/* <nav class="pagination-totalpages">
                            <span class="hOXYFc">Rows per page:</span>
                            <select>
                                <option value="10">10</option>
                                <option value="20" selected="">20</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span class="fwhfat">1-20 of 22</span>
                            <div class="bcYJHc"><button id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="" className="gryXwN"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></button>
                                <button id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled="" className="gryXwN"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                                <button id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="false" className="gryXwN" fdprocessedid="8wrtk"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></button>
                                <button id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="false" className="gryXwN" fdprocessedid="mhvkeh"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></button>
                            </div></nav> */}
                            </div>  
                    <div className="table-responsive">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page, totalRows) => {
                                setCurrentPage(page);
                            }}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer
                            fixedHeader
                            customStyles={tableCustomStyles}
                            fixedHeaderScrollHeight={dataTableHeight}
                            selectableRows
                              onSelectedRowsChange={({ selectedRows }) =>
                              console.log(selectedRows)
                            }
                            selectableRowsHighlight
                            highlightOnHover
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            subHeader
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            subHeaderComponent={
                                <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}>
                        </DataTable>
                    </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { ServiceGroupList }