import React,{useState,useEffect} from "react";
import Masters from "../Components/masters.json"
import { DashboardLayout, DeletePopupModal,PopupModal, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig"
const Signature = ({data,HandleCount}) => {
    const [Data, setData] = useState({})
    const [Error, SetError] = useState("")
    const[popupType,setpopupType]=useState("")
    const [loading, setLoading] = useState(false);
    const [signatureImage, setSignatureImage] = useState(null);
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        console.log(name, value)
        setData({ ...Data, [name]: value })
        handleFileChange(value)
    }
    const handleFileChange = (file) => {
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setSignatureImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };
    const formSubmit = (e,count) => {
        e.preventDefault();
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.patch(`/account/users/${data.id}/`,Data).then(res => {
            console.log(res)
            setpopupType("success")
            HandleCount(count==="Back"?3:5)
            data["digital_signator"]=res.data.digital_signator
        }).catch(error => {
            setpopupType("error")
        })
}
useEffect(() => {
   if(data){
    setSignatureImage(data.digital_signator)
   }
  }, [data]);
    return (
        <div>
        <form className="prefrence-paddin">
            <div className="row">
                <div className="col-6 form-group">
                    <label>Current Signature:</label>
                    <div className="input-container">
                        {signatureImage ? (
                            <img
                                src={signatureImage}
                                alt="Digital Signature"
                                className="current-signature"
                            />
                        ):<div>Current Signature</div>}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label >Current Signature:</label>
                        <input type="file" className="form-control" placeholder="Current Signature" name="digital_signator" onChange={HandleFiles} required />
                    </div>
                </div>
            </div>
        </form>
        {popupType != "" &&
         <PopupModal popupType={popupType} setpopupType={setpopupType}/>
     }
        </div>
    )
}
export { Signature }