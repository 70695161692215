import React,{useState} from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const StateAdd = () => {
    const[Error,SetError]=useState("")
    const[CustomError,SetCustomError]=useState("")
    const[CustomError1,SetCustomError1]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.State
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/states/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error.response.data)
            const ErrorData=[]
            ErrorData.push({
                    state_name:error.response.data.state_name||["null"],
                    country_id:error.response.data.country_id||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].state_name[0]==="This field must be unique."){
                SetError("State Already Exists")
            }
            if(ErrorData[0].state_name[0]==="Enter a valid state name."){
                SetError("Enter a valid state name.")
            }
            if(ErrorData[0].state_name[0]==="This field may not be blank."){
                SetError("This field may not be blank.")
            }
            if(ErrorData[0].country_id[0]==="This field may not be null."){
                SetError("This field may not be null.")
            }
                setpopupType("error")
            
        })
      }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add State</h5>
                    <NavLink to="/dashboard/masters/states" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} CustomError={CustomError} CustomError1={CustomError1}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/states" />
                }
            </DashboardLayout>
        </>
    )

}
export {StateAdd}