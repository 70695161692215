import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, EditPopupModal, DeletePopupModal, Pagination, PopupModal } from "../Components";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { useParams } from "react-router-dom";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles"
import Select from 'react-select';
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import {TiTick} from "react-icons/ti"
const TariffCardEditCustomEdit = ({ Code }) => {
    let { id } = useParams();
    const [popupType, setpopupType] = useState("");
    const [EditPopupType, setEditpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [actionPermisson, SetActionPermission] = useState([])
    const MastersModal = Masters.TariffCard
    const [Error, SetError] = useState("")
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [Service, setService] = useState()
    const [EditTariffChange, setEditTarifChange] = useState([])
    const [check, setCheck] = useState(false)
    const[tableId,setTableId]=useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const [Priorities, SetPriorities] = useState([])

    const GetPriorities = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/masters/priority/`);
            console.log(response, 'response')
            SetPriorities(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/client/clienttariff/${id}/custom_pagination/?page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            console.log(response, 'response')
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const toggleCheckbox = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        setCheck(true)
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const [TariffEditData, SetTariffEditData] = useState([])
    const clientEditData = () => {
        axiosConfig.get(`/masters/tariffcategories/${id}/`).then(res => {
            console.log(res.data)
            SetTariffEditData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const HandleMouse=(row)=>{
        setTableId(row.service);
    }
    const HandleRowmouseLeave=()=>{
        setTableId()
    }
    useEffect(() => {
        clientEditData()
        GetPriorities()
        GetData();
        PermitData()
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/client/clienttariff/${id}/custom_pagination/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }

    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const formSubmit = () => {
        axiosConfig.patch(`/masters/tariffcategories/${id}/`, TariffEditData).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const handleTariff=()=>{
        EditTariffChange.map((tarif,idx)=>{
            axiosConfig.patch(`/client/clienttariff/${tarif[`priority_id${idx+1}`]}/`, {"serice_amount":tarif[`service_amount_${idx+1}`]}).then(res => {
                GetData()
                setpopupType("success")
                setService()
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            })
        })
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Service Name",
            selector: "service_name",
            cell: (row) => `${row.service_name}`,
            sortable: true,
            width: "300px",
        },
        {
            name: "Subdepartment Code",
            selector: "subdepartment_code",
            cell: (row) => `${row.subdepartment_code}`,
            sortable: true,
        },{
            name: "ACTIONS",
            selector: (row) => {
                const HandleEdit = async () => {
                    setService(row.service)
                    setEditTarifChange([{
                        "service_amount_1": row.service_amount_1,
                        "priority_id1": row.priority_id1
                    },
                    {
                        "service_amount_2": row.service_amount_2,
                        "priority_id2": row.priority_id2
                    },
                    {
                        "service_amount_3": row.service_amount_3,
                        "priority_id3": row.priority_id3
                    }
                    ])
                }
                const ActionsAccrss=Service===row.service
                const shouldShowActions=tableId===row.service
                return (
    
                    <>
                        {
                            shouldShowActions && (
                                <div className="row text-center" >
                                {actionPermisson && actionPermisson.edit && <div className="col-4">
                                {
                                    ActionsAccrss ?<>
                                    <TiTick className="dashboard-tabe-data-actions" onClick={handleTariff}/>
                                    </>:
                                    <>
                                     <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                    </>
                                }
                                   
                                </div>}
        
                            </div>
                            )
                        }
                    </>
                )
    
            },
        }
    ]
    const HandleTariffChange = (e, priority, index) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const existingIndex = EditTariffChange.findIndex(item => item[`priority_id${index}`] === priority);
        if (existingIndex === -1) {
            setEditTarifChange(prevState => [...prevState, { [name]: value, [`priority_id${index}`]: priority }]);
        } else {
            setEditTarifChange(prevState => {
                const updatedValues = [...prevState];
                updatedValues[existingIndex] = { [name]: value, [`priority_id${index}`]: priority };
                return updatedValues;
            });
        }
    };
    const sortedPriorities = Priorities.sort((a, b) => a.id - b.id);
    sortedPriorities.map((priority, idx) => {
        columns.push({
            name:priority.code,
            selector: `service_amount_${idx + 1}`,
            sortable: true,
            cell: row => {
                const serviceAmount = row[`service_amount_${idx + 1}`];
                const Actions = Service === row.service;
    
                if (Actions) {
                    return (
                        <>
                            <input className="form-control" defaultValue={serviceAmount} name={`service_amount_${idx + 1}`} onChange={(e) => HandleTariffChange(e, row[`priority_id${idx + 1}`], idx + 1)} />
                        </>
                    );
                }
    
                return (
                    <>
                        <span>{serviceAmount}</span>
                    </>
                );
            }
        });
    });
    return (
        <>
            <DashboardLayout>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} formData={TariffEditData} error={Error} />
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                    </div>
                    <div className="table-responsive">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page, totalRows) => {
                                setCurrentPage(page);
                                GetData(currentColumn, currentDirection);
                            }}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer
                            fixedHeader
                            customStyles={tableCustomStyles}
                            fixedHeaderScrollHeight="450px"
                            selectableRows
                            onSelectedRowsChange={({ selectedRows }) =>
                                console.log(selectedRows)
                            }
                            selectableRowsHighlight
                            highlightOnHover
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            subHeader
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            subHeaderComponent={
                                <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}>
                        </DataTable>
                    </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
                {popupType != "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { TariffCardEditCustomEdit }