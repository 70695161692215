import React, { useEffect, useState,useRef } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import {MdOutlineRefresh} from "react-icons/md"
import {CiMenuKebab} from "react-icons/ci"
const StateList = ({ Code }) => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [loading, setLoading] = useState(false)
    const [actionPermisson, SetActionPermission] = useState([])
    const [Datafilter, SetDatafilter] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const[check,setCheck]=useState(false)
    const[tableId,setTableId]=useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
          const response = await axiosConfig.get(
            `/masters/states/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
            currentDirection === "asc" ? currentColumn : `-${currentColumn}`
            }`
        );
          SetData(response.data.results);
          setLoading(false);
          setTotalRows(response.data.count); // Update totalRows with the correct total count from the API response
        } catch (error) {
          console.log(error);
        }
      }; 
      const [isOpen, setIsOpen] = useState(false);
      const dropdownRef = useRef();
  
      const toggleCheckbox = () => {
          setIsOpen(!isOpen);
      };
  
      const handleCheckboxClick = (e) => {
          e.stopPropagation();
          setCheck(true)
      };
      const handleDropdownToggle = () => {
          setIsOpen(!isOpen);
      }; 
      const [dataTableHeight, setDataTableHeight] = useState('500px');
      const calculateDataTableHeight = () => {
          const screenHeight = window.innerHeight;
          console.log(screenHeight)
          const newHeight = `${screenHeight - 180}px`; 
          setDataTableHeight(newHeight);
      };    
    useEffect(() => {
        calculateDataTableHeight();
        GetData();
        PermitData()
    }, [currentPage, RowPerPage,currentColumn, currentDirection])

    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        const find_data = data.find(res => res.modulescreen.code === Code)
        SetActionPermission(find_data)
    }
console.log(actionPermisson.delete)
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/masters/states/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
     const HandleMouse=(row)=>{
        setTableId(row.id)
     }
     const HandleRowmouseLeave=()=>{
        setTableId()
     }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Short Name",
            selector: "short_name",
            cell: (row) => `${row.short_name}`,
            sortable: true
        },
        {
            name: "State Name",
            selector: "state_name",
            cell: (row) => `${row.state_name}`,
            sortable: true
        },
        {
            name: "Country",
            selector: "country_name",
            cell: (row) => `${row.country_name}`,
            sortable: true,
            style: {
                width: "100%",
            },
        },
        {
            name: "ACTIONS",
            cell: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/states/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`masters/states/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const HandleView = async (id) => {
                    return navigate(`/dashboard/masters/states/${row.id}/edit`);
                }
                const shouldShowActions = tableId === row.id;
                return (
                    <>
                        {shouldShowActions &&(
                           <div className="row text-center" >
                           {actionPermisson && actionPermisson.read && <div className="col-4">
                               <AiOutlineEye className="dashboard-tabe-data-actions" onClick={HandleView} />
                           </div>}
                           {actionPermisson && actionPermisson.edit && <div className="col-4">
                               <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                           </div>}
                           {actionPermisson && actionPermisson.delete && <div className="col-4">
                               <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                           </div>}
                       </div>
                        )
                       }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View States</h5>
                    {actionPermisson && actionPermisson.write && <NavLink to="/dashboard/masters/states/add" ><button className="btn btn-white">Add New</button></NavLink>}
                </div>
                <div className="tablescroll-clientbill">
                    <div className="table-header-menu">
                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" />
                        </div>
                <div className="table-responsive">
                <DataTable
                    columns={columns}
                    data={Data}
                    pagination
                    paginationTotalRows={totalRows}
                    paginationPerPage={RowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page, totalRows) => {
                        setCurrentPage(page);
                        GetData(currentColumn, currentDirection);
                    }}
                    paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                    onSort={handleSort}
                    paginationServer
                    fixedHeader
                    customStyles={tableCustomStyles}
                    subHeader
                    fixedHeaderScrollHeight={dataTableHeight}
                    selectableRows
                      onSelectedRowsChange={({ selectedRows }) =>
                      console.log(selectedRows)
                    }
                    selectableRowsHighlight
                    highlightOnHover
                    onRowMouseEnter={HandleMouse}
                    onRowMouseLeave={HandleRowmouseLeave}
                    progressPending={loading}
                    progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}
                    subHeaderComponent={
                        <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}>
                </DataTable>
                </div>
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { StateList }