import React, { useEffect, useState } from "react";
import { AiFillSetting ,AiOutlineQuestionCircle,AiOutlineSetting} from 'react-icons/ai';
import { HiUserGroup } from 'react-icons/hi';
import { RiBillFill } from 'react-icons/ri';
import { MdWork } from 'react-icons/md';
import { MdLibraryBooks } from "react-icons/md"
import { HiDocumentReport } from 'react-icons/hi';
import { MdCircleNotifications,MdSearch } from "react-icons/md"
import { RiLogoutCircleRLine } from "react-icons/ri"
import { GoBell } from 'react-icons/go';
import {GrSort} from "react-icons/gr";
import {CgMenuGridR} from "react-icons/cg"
import "./DashboardLayout.css"
import sidebar from "./sidebar";
import { ChildNav } from "./ChildNav";
import { useNavigate } from "react-router";
import {BsPersonWorkspace} from "react-icons/bs"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const DashboardLayout = ({ children }) => {
    const Navigate = useNavigate();
    const [smShow, setSmShow] = useState(false);
    const [childData, setChildData] = useState([])
    const [Toggle, SeToggle] = useState(true)
    const [ParentNav, SetParentNav] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const [moduleAccess, SetModuleAccess] = useState([])
    const handleNav = (event) => {
        let parentnav = event.currentTarget.dataset.parentnav
        SetParentNav(parentnav)
        localStorage.setItem('ParentNav', parentnav);
        setChildData(sidebar[parentnav])
    }
    const AlertLogout = () => {
        setSmShow(!smShow)
    }
    const handleLogout = (event) => {
        localStorage.removeItem('token');
        setSmShow(false)
        Navigate("/")
    }
    const PermitData=()=>{
        const Permmitdata=localStorage.getItem("permission")
        const data=JSON.parse(Permmitdata)
        let moduledata=[]
        data.forEach(pms => {
            if(!moduledata.includes(pms.modulescreen.module_code)){
                moduledata.push(pms.modulescreen.module_code)
            }
        });
        SetModuleAccess(moduledata)
    }
    console.log(moduleAccess)
    const getParentNav = async () => {
        let parentnav = localStorage.getItem('ParentNav')
        let childnavpath = localStorage.getItem('ChildNavs')
        if (parentnav) {
            SetParentNav(parentnav)
            SetChildNavs(childnavpath)
            setChildData(sidebar[parentnav])
        } else {
            setChildData(sidebar["masters"])
            localStorage.setItem('ParentNav', "masters");
        }
    }
    const childNavClick = (childnavpath) => {
        localStorage.setItem('ChildNavs', childnavpath);
        SetChildNavs(childnavpath)
    }
    useEffect(() => {
        getParentNav();
        PermitData()
    }, [])

    return (
        <>
            <div className="dashboard-layout">
                <header>
                    <div className="Dashboard-brand">
                        <img className="DashBoard-toggle" src={require("./images/Toggle.png")} alt="toggle" onClick={() => SeToggle(!Toggle)} />
                    </div>
                    <div className="dashboard-side-header">
                        <div>
                    <img className="DashBoard-Logo" src={require("./images/Logo.png")} alt="logo" />
                    </div>
                    <div className="search-dashborad">
                        <MdSearch  className="sidebar-icon"/>
                            <input className="input-control" placeholder="Search"/>
                            <GrSort  className="sidebar-icon"/>
                        </div>
                        <div className="dashboard-menu">
                    <div className="select-dashborad">
                        <select>
                           <option>Online</option>
                           <option>Offline</option> 
                        </select>
                       </div>
                       <AiOutlineQuestionCircle className="sidebar-icon" />
                       <AiOutlineSetting className="sidebar-icon" />
                       <CgMenuGridR className="sidebar-icon" />
                        </div>
                        <div>
                        <button className="btn btn-white" onClick={AlertLogout} ><div className="button-docotr">
                            <span className="doctor-name">{localStorage.getItem("first_name")}</span>
                            <span className="doctor-account">0</span>
                            </div>
                            </button>
                        </div>
                    </div>
                </header>
                <aside>
                    <nav className="sidebar-main">
                       {moduleAccess.includes("ARIMC1")&&<div onClick={handleNav} className={ParentNav == "worklists" ? "navitem active" : "navitem"} data-parentnav="worklists" data-code="ARIMC1" >
                            <BsPersonWorkspace className="sidebar-icon" />
                            <p className="sidebar-name">Work Lists</p>
                        </div>}
                        {moduleAccess.includes("ARIMC2")&&<div onClick={handleNav} className={ParentNav == "accounts" ? "navitem active" : "navitem"} data-parentnav="accounts" data-code="ARIMC2" >
                            <HiUserGroup className="sidebar-icon" />
                            <p className="sidebar-name">User Accounts</p>
                        </div>} 
                        {moduleAccess.includes("ARIMC3")&&<div onClick={handleNav} className={ParentNav == "billing" ? "navitem active" : "navitem"} data-parentnav="billing" data-code="ARIMC3" >
                            <RiBillFill className="sidebar-icon" />
                            <p className="sidebar-name">Billing</p>
                        </div>}
                        {moduleAccess.includes("ARIMC4")&&<div onClick={handleNav} className={ParentNav == "finance" ? "navitem active" : "navitem"} data-parentnav="finance" data-code="ARIMC4" >
                            <MdLibraryBooks className="sidebar-icon" />
                            <p className="sidebar-name">Finance</p>
                        </div>}
                        {moduleAccess.includes("ARIMC5")&&<div onClick={handleNav} className={ParentNav == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" data-code="ARIMC5">
                            <HiDocumentReport className="sidebar-icon" />
                            <p className="sidebar-name">Reports</p>
                        </div>}
                        {moduleAccess.includes("ARIMC6")&&<div onClick={handleNav} className={ParentNav == "assets" ? "navitem active" : "navitem"} data-parentnav="assets" data-code="ARIMC6" >
                            <MdWork className="sidebar-icon" />
                            <p className="sidebar-name">Assets</p>
                        </div>}
                        {moduleAccess.includes("ARIMC7")&&<div onClick={handleNav} className={ParentNav == "masters" ? "navitem active" : "navitem"} data-parentnav="masters" data-code="ARIMC7">
                            <AiFillSetting className="sidebar-icon" />
                            <p className="sidebar-name">Settings</p>
                        </div>}
                    </nav>
                </aside>
                {Toggle && <nav className="sidebar-child">
                        <div className="sidebar-child-container">
                            <ChildNav childData={childData} childNavClick={childNavClick} ChildNavs={ChildNavs} />
                        </div>
                    </nav>}
                <div className="dashboard-content">
                    {children}
                </div>
            </div>
            {/* <footer>
             <div>
                <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                </p>
            </div>
            </footer> */}
            <Modal
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body className="success-icon text-center">
                    <h5 className="successmsg">Are you sure you want to logout ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                        Cancel
                    </button>
                    <button variant="primary" className="btn btn-primary" onClick={handleLogout}>
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export { DashboardLayout }
