import React, { useEffect, useState } from "react";
import { DashboardLayout, PopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const UploadCases = () => {
    const [popupType, setpopupType] = useState("");
    const [Data, setData] = useState({
        month: "",
        file: ""
    })
    const [Error, SetError] = useState([])
    const [Errors, SetErrors] = useState("")
    const [Loading, setLoading] = useState()
    const [lgShow, setLgShow] = useState(false);
    const HandleChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        console.log(name, value);
        setData({ ...Data, [name]: value })
    }
    const HandleMonth = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        console.log(name, value);
        setData({ ...Data, [name]: value })
    }
    const HandleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("file", Data && Data.file)
        formData.append("month", Data && Data.month)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        setLoading(true)
        axiosConfig.post("/cases/Uploadfile/upload_cases_new/", formData).then(res => {
            setpopupType("success")
            setLoading(false)
            console.log(res)
        }).catch(error => {
            console.log(error.response.data, Data.file.name)
            if (error.response.data === "csv not created") {
                SetErrors(`${Data.file.name} Is Not CSV File`)
                setLoading(false)
                setLgShow(true)
            }
            if (Array.isArray(error.response.data)) {
                SetError(error.response.data);
                setLoading(false)
                setLgShow(true)
            } else {
                SetError("");
            }
        })

    }
    const DownloadSampleCSV = () => {
        axiosConfig.get("CSVFiles/Sample_CSV.csv").then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `SampleCSV.csv`);
            document.body.appendChild(link);
            link.click();
        })
            .catch(error => {
                console.error('There was an error downloading the PDF', error);
            })

    }

    console.log(Data)
    return (
        <>
            <DashboardLayout>
                <form onSubmit={HandleSubmit}>
                    <div className="dashboard-header">
                        <h5 className="dashboard-subtitle">Upload Raw Data/ Cases</h5>
                        <input type="month" name="month" className="form-control month-selector" onChange={HandleMonth} required />
                        <NavLink to="/dashboard/billing/upload-cases-list" ><button className="btn btn-primary mx-2">View Cases</button></NavLink>
                    </div>
                    <div className="container-form">
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    <h5 className="dashboard-subtitle">Upload Errors</h5>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    {Error.length>0?Error.map(obj =>
                                        Object.entries(obj).map(([key, value]) => (
                                            <div key={key} className=" LoginError">
                                            <p key={key}>{key}: {value}</p>
                                        </div>
                                            
                                        ))
                                    ):<div  className=" LoginError">
                                    <p>{Errors}</p>
                                </div>}
                                </div>


                            </Modal.Body>
                        </Modal>
                        <label htmlFor="file-input" className="form-group uploadBox">
                            <label htmlFor="file-input">
                                {Loading ? (
                                    <div>
                                        <img className="upload-loader" src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        <h5 className="upload-head">{Data.file ? Data.file.name && Data.file.name : "Upload Raw Data"}</h5>
                                    </div>) : <>
                                    <img src={require("./images/upload.png")} alt="upload" />
                                    <h5 className="upload-head">{Data.file ? Data.file.name && Data.file.name : "Upload Raw Data"}</h5>
                                </>}
                            </label>
                            <input id="file-input" type="file" className="form-control" name="file" onChange={HandleChange} required />
                        </label>
                        <button type="submit" className="btn btn-primary btn-block" >Submit</button>
                    </div>
                </form>
                <p className="upload-Download" onClick={DownloadSampleCSV}>Download Sample CSV</p>
                <p>Please follow below instructions before upload the CSV File:</p>
                <ul className="instructions">
                    <li>Format Date - DDMMMYYYY HH:MM:SS</li>
                    <li>Don't upload empty rows</li>
                </ul>
                {/* <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div> */}
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/billing/upload-cases" Message="Record Uploades SuccessFully" />
                }
            </DashboardLayout >
        </>
    )
}
export { UploadCases }