import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const AreaAdd = () => {
    const [Error, SetError] = useState("")
    const [data, SetData] = useState({
        city_or_town_id: "",
        code: "",
        name: "",
        pincode: "",
        address: ""
    })
    const[CustomError1,SetCustomError1]=useState("")
    const [CustomError, SetCustomError] = useState("")
    const [popupType, setpopupType] = useState("")
    const MastersModal = Masters.Area
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("/masters/area/", Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
                const formData=[]
                formData.push({city_or_town_id:error.response.data.city_or_town_id||["null"],
                    code:error.response.data.code||["null"],
                    pincode:error.response.data.pincode||["null"],
                    address:error.response.data.address||["null"]})
                    console.log(formData[0])
            if (formData[0].code[0] === "This field must be unique.") {
                SetError("Code Already Exists")
            }
            if (formData[0].city_or_town_id[0] === "This field may not be null.") {
                SetError("This field may not be null.")
            }
            if(formData[0].address[0]==="This field must be unique."){
                SetError("Address Already Exists")
            }
            if (formData[0].pincode[0] === "Only 0-9 are allowed.") {
                SetError("Invalid PinCode.")
            }
            else {
                setpopupType("error")
            }
        })
    }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Area</h5>
                    <NavLink to="/dashboard/masters/areas" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} CustomError={CustomError} CustomError1={CustomError1
                }  />
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/areas" />
                }
            </DashboardLayout>
        </>
    )

}
export { AreaAdd }