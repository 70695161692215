import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const CityEdit = () => {
    const[popupType,setpopupType]=useState("")
    const[Error,SetError]=useState("")
    const MastersModal=Masters.City
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/cities/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData = []
            ErrorData.push({
                city_name: error.response.data.city_name || ["null"],
                state_id: error.response.data.state_id || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].city_name[0] === "This field is required.") {
                SetError("City is required.")
            }
            if (ErrorData[0].state_id[0] === "This field is required.") {
                SetError("State is required.")
            }
            if (ErrorData[0].city_name[0] === "Enter a valid cityortown name.") {
                SetError("Enter a valid city or town name.")
            }
            if (ErrorData[0].city_name[0] === "already exists") {
                SetError("City Already Exists")
            }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/cities/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit City {id}</h5>
                    <NavLink to="/dashboard/masters/cities" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/cities" />
                }
            </DashboardLayout>
        </>
    )

}
export {CityEdit}